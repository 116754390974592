<template>
  <main class="page-front">
    <div class="filter-container swiper" v-if="this.items" ref="swiper">
      <div class="swiper-wrapper" ref="sw">
        <div
          class="item-wrapper filtr-item swiper-slide"
          v-bind:data-category="item.date"
          data-sort="value"
          v-bind:data-date="item.date"
          v-bind:data-range="item.range"
          v-for="item in this.items"
          v-bind:key="item.id"
          ref="items"
        >
          <router-link :to="'/project/' + item.id">
            <div>
              <div
                class="item-background"
                v-bind:style="{ 'background-image': 'url(' + item.cover + ')' }"
              ></div>
            </div>
            <span v-if="this.$i18n.locale == 'en'">{{ item.etitle }}</span>
            <span v-if="this.$i18n.locale == 'pl'">{{ item.title }}</span>
          </router-link>
        </div>
      </div>
    </div>
    <div class="main-arrows" v-if="this.items">
      <div class="main-arrows-content">
        <div
          class="main-arrow-bottom"
          @mouseenter="hover_b = true"
          @mouseleave="hover_b = false"
          ref="arrow_bottom"
        >
          <img v-if="hover_b == false" src="@/assets/gfx/arrowgr.svg" />
          <img v-else src="@/assets/gfx/arrow.svg" />
        </div>
        <div
          class="main-arrow-top"
          @mouseenter="hover_u = true"
          @mouseleave="hover_u = false"
          ref="arrow_top"
        >
          <img v-if="hover_u == false" src="@/assets/gfx/arrowgr.svg" />
          <img v-else src="@/assets/gfx/arrow.svg" />
        </div>
      </div>
    </div>
  </main>
</template>
<script>
//import noUiSlider from "nouislider";
import { Swiper, Mousewheel, Navigation } from "swiper"; // eslint-disable-line
import "swiper/css";
import Filterizr from "filterizr";

Swiper.use([Mousewheel, Navigation]);

//import wNumb from "wnumb";
/* eslint-disable */
// to do sidebara!
//require("@/assets/nouislider.min.css");

export default {
  name: "VerticalSlider",
  props: ["items"],
  data: function () {
    return {
      filterizr: null,
      swiper: null,
      hover_b: false,
      hover_u: false
    };
  },
  mounted: function () {
    //console.log([this.hover_u, this.hover_b]);
    this.setUpSlider();
    this.filterizr = new Filterizr(this.$refs.sw);
    //const filterizr = new Filterizr(this.$refs.sw);
    this.filterizr.filter("all");
    //this.changeSwiper();
    let _this = this;
    window.addEventListener("resize", function () {
      if (_this.swiper) {
        _this.swiper.update();
        _this.swiper.navigation.update();
        //_this.swiper.update();
      }
    });
    window.addEventListener("orientationchange", function () {
      if (_this.swiper) {
        _this.swiper.update();
        _this.swiper.navigation.update();
        //_this.swiper.update();
      }
    });
  },
  methods: {
    toggleSidebar() {
      this.$emitter.emit("toggleSidebar", {});
      this.active = !this.active;
    },
    setUpSlider() {
      this.$nextTick(() => {
        let swiper = new Swiper(this.$refs.swiper, {
          direction: "vertical",
          init: false,
          autoHeight: true,
          slidesPerView: 2,
          centeredSlides: true,
          centeredSlidesBounds: true,
          initialSlide: 3,
          //observer: true, 
          //observeParents: true,
          mousewheel: true,
          setWrapperSize: true,
          // navigation: {
          //   nextEl: this.$refs.arrow_bottom,
          //   prevEl: this.$refs.arrow_top,
          // },
          navigation: {
            nextEl: ".main-arrow-bottom",
            prevEl: ".main-arrow-top",
          },
          //freeMode:true
          // mousewheel: {
          //   invert: true,
          //   eventsTarget: this.$refs.swiper
          // }
        });


        // initial slide = wylicz ktor slide w danych jest initial, wtedy ustaw slidera

        swiper.init();
        this.swiper = swiper;
        this.swiper.navigation.init();
        //console.log(this.swiper.navigation);
        let i = this.$refs.sw;
        //let i = this.$refs.swiper;
        // do i daj daty
        let _this = this;
        this.$emitter.emit("swiperReady", { items: _this.items });
        this.changeSwiper();
      });
    },
    changeSwiper() {
      let _this = this;
      this.$emitter.on("filtersChanged", (values) => {
        this.$Progress.start();
        let slides = this.$refs.items;
        this.filterizr.filter("all");

        // slides.forEach((slide) => {
        //   let data = slide.getAttribute("data-date");
        //   if (data >= values.values[0] && data <= values.values[1]) {
        //     slide.setAttribute("data-category", 1);
        //   } else {
        //     slide.setAttribute("data-category", 0);
        //   }
        // });

        var range = (start, stop, step) =>
          Array.from(
            { length: (stop - start) / step + 1 },
            (_, i) => start + i * step
          );
        let common_range = range(
          Number(values.values[0]),
          Number(values.values[1]),
          1
        );

        slides.forEach((slide) => {
          slide.setAttribute("data-category", 1);
          let data = slide.getAttribute("data-date");
          let range_data = slide.getAttribute("data-range").split(",");
          if (
            (Math.min(...range_data) >= values.values[0] &&
              Math.min(...range_data) <= values.values[1]) ||
            (Math.max(...range_data) >= values.values[0] &&
              Math.max(...range_data) <= values.values[1]) ||
            (Math.min(...range_data) <= values.values[0] &&
              Math.max(...range_data) >= values.values[1])
            //Math.min(...range_data) >= values.values[0] ||
            //Math.max(...range_data) <= values.values[1]
          ) {
            slide.setAttribute("data-category", 1);
            //slide.setAttribute("style", "display: inherit;")
            slide.classList.remove("hide-me-pls");
          } else {
            slide.setAttribute("data-category", 0);
            //slide.setAttribute("style", "display: none;")
            slide.classList.add("hide-me-pls");
          }
          //console.log("swiper update")
          //console.log(this.swiper);
          this.swiper.update();
          this.swiper.navigation.update();
        });

        _this.filterizr.filter(1);

        _this.filterizr.setOptions({
          callbacks: {
            onFilteringEnd: function () {
              ////console.log(_this.swiper);
              _this.$Progress.finish();
            }
          }
        });
      });
    }
  }
};
</script>
