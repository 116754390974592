<template>
  <basic
    :items="items"
    :title="title"
    :aside="aside"
    :main="main"
    :project="project"
    :metadata="metadata"
    :contests="contests"
    :breadcrumbs="breadcrumbs"
    :ready="ready"
    v-if="metadata"
  />
</template>
<script>
// common scripts

import httpClient from "@/scripts/httpClient";
//import findTranslatedData from "@/scripts/findTranslatedData";
import iterateMetadataFields from "@/scripts/iterateMetadataFields";
import buildPersonSet from "@/scripts/buildPersonSet";
import { bodyTag } from "@/scripts/bodyTag";
import getAlternativeTitle from "@/scripts/getAlternativeTitle";

// base component
import Basic from "@/components/Basic.vue";

export default {
  name: "ProjectView",
  components: {
    Basic,
  },
  data: function () {
    return {
      data: [],
      count: null,
      page: 1,
      items: null,
      project: null,
      metadata: null,
      contests: null,
      aside: "aside-project",
      main: "main-project",
      title: this.customConfig.PAGE_TITLES.HOME,
      ready: false,
      breadcrumbs: null,
    };
  },
  created: function () {
    this.sendReq();
    bodyTag(this.$route.name);
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.breadcrumbs = {};
      vm.breadcrumbs.from = from;
    });
  },
  watch: {
    data: {
      deep: true,
      handler(data) {
        if (data.length != this.count) {
          this.page++;
          this.sendReq();
        } else {
          this.setUpSlides();
        }
      },
    },
  },
  methods: {
    sendReq() {
      this.$Progress.start();
      // 1. set up project items data
      httpClient(
        this.customConfig.API_PATH +
          "/items?property[0][joiner]=and&property[0][property]=33&property[0][type]=res&property[0][text]=" +
          this.$route.params.project_id +
          "&sort_by=created&sort_order=desc&page=" +
          this.page
      )
        .then((resp) => {
          this.count = Number(resp.headers["omeka-s-total-results"]);
          this.data = this.data.concat(resp.data);
        })
        .catch((error) => {
          this.$router.push({ name: "error-page", params: { error: error } });
        });
    },
    setUpSlides() {
      if (this.data.length > 0) {
        this.items = [];
        this.data.forEach((e) => {
          let _order = 99999999999999;
          if (typeof e["curation:rank"] !== "undefined") {
            _order = Number(e["curation:rank"][0]["@value"]);
          }

          // check for translations
          // default
          let _title = e["o:title"];
          let _etitle = e["o:title"];

          // try

          if (typeof e["dcterms:alternative"] !== "undefined") {
            if (e["dcterms:alternative"].length > 0) {
              e["dcterms:alternative"].forEach((at) => {
                if (at["@language"] === "pl") _title = at["@value"];
                if (at["@language"] === "en") _etitle = at["@value"];
              });
            }
          }

          this.items.push({
            id: e["o:id"],
            //title: getAlternativeTitle("pl", e),
            //etitle: getAlternativeTitle("en", e),
            title: _title,
            etitle: _etitle,
            uri: "/",
            cover: e["thumbnail_display_urls"].large,
            thumbnail: e["thumbnail_display_urls"].medium,
            order: _order,
          });

          this.items.sort((a, b) => a.order - b.order);
        });
      } else {
        this.items = null;
      }
      this.buildMetadataSet();
    },
    buildMetadataSet() {
      // when all items are done
      // set up project core data

      httpClient(
        this.customConfig.API_PATH + "/items/" + this.$route.params.project_id
      )
        .then((resp) => {
          this.project = resp.data;
          let _this = this;
          if (this.project) {
            buildPersonSet(this.project, this.data, function (network) {
              let _metadata = {
                title: getAlternativeTitle("pl", _this.project),
                etitle: getAlternativeTitle("en", _this.project),
                fields: iterateMetadataFields(_this.project),
                network: network,
              };
              _this.metadata = _metadata;
              _this.title =
                _this.customConfig.PAGE_TITLES.HOME +
                " : " +
                _this.metadata.title;
              setTimeout(function () {
                //console.log(_this.metadata);
                _this.$Progress.finish();
                _this.ready = true;
              }, 100);
            });
          }
        })
        .catch((error) => {
          this.$router.push({ name: "error-page", params: { error: error } });
        });
    },
  },
};
</script>
<style scoped></style>
