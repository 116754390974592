<template>
  <div
    id="bc"
    class="breadcrumbs"
    v-if="ready && breadcrumbs"
    aria-label="breadcrumbs"
    :key="key"
  >
    <ul v-if="this.$i18n.locale == 'pl'">
      <li>
        <router-link to="/" :title="$t('home_page')">
          {{ $t("home_page") }} &nbsp;|&nbsp;
        </router-link>
      </li>
      <li v-if="public_path.middle" id="middle">
        <router-link
          :to="public_path.middle.path"
          :title="public_path.middle.title"
          >{{ public_path.middle.title }}</router-link
        >
        <span>&nbsp;|&nbsp;</span>
      </li>
      <li v-if="public_path.first" id="first">
        <router-link
          :to="public_path.first.path"
          :title="public_path.first.title"
          >{{ public_path.first.title }}</router-link
        >
        <span v-if="public_path.middle || public_path.second"
          >&nbsp;|&nbsp;</span
        >
      </li>
      <li v-if="public_path.second" id="second">
        {{ public_path.second.title }}
      </li>
    </ul>
    <ul v-if="this.$i18n.locale == 'en'">
      <li>
        <router-link to="/" :title="$t('home_page')">
          {{ $t("home_page") }} &nbsp;|&nbsp;
        </router-link>
      </li>
      <li v-if="public_path.middle" id="middle">
        <router-link
          :to="public_path.middle.path"
          :title="public_path.middle.etitle"
          >{{ public_path.middle.etitle }}</router-link
        >
        <span>&nbsp;|&nbsp;</span>
      </li>
      <li v-if="public_path.first" id="first">
        <router-link
          :to="public_path.first.path"
          :title="public_path.first.etitle"
          >{{ public_path.first.etitle }}</router-link
        >
        <span v-if="public_path.middle || public_path.second"
          >&nbsp;|&nbsp;</span
        >
      </li>
      <li v-if="public_path.second" id="second">
        {{ public_path.second.etitle }}
      </li>
    </ul>
  </div>
</template>
<script>
/* eslint-disable */
import httpClient from "@/scripts/httpClient";
import findTranslatedData from "@/scripts/findTranslatedData";
import getAlternativeTitle from "@/scripts/getAlternativeTitle";

export default {
  name: "BreadCrumbs",
  props: ["title", "etitle", "breadcrumbs"],
  data: function () {
    return {
      to: null,
      from: null,
      view: this.$route.name,
      parent: null,
      ready: false,
      public_path: null,
      key: Math.random(),
    };
  },
  watch: {
    $props: {
      handler() {
        this.key = Math.random();
        this.getTitles();
      },
      deep: true,
      immediate: true,
    },
  },
  mounted: function () {
    ////console.log([this.title, this.etitle, this.breadcrumbs]);
    this.getTitles();
  },
  methods: {
    getTitles() {
      if (this.view == "item" || this.view == "project") {
        httpClient(
          this.customConfig.API_PATH + "/items/" + this.$route.params.project_id
        )
          .then((resp) => {
            this.parent = {
              title: getAlternativeTitle("pl", resp.data),
              etitle: getAlternativeTitle("en", resp.data),
              //title: resp.data["o:title"],
              //etitle: findTranslatedData("dcterms:title", resp.data),
              // only id pls?
              path: null
            };
            this.ready = true;
            this.setBreadCrumbs();
          })
          .catch((error) => {
            //this.$router.push({ name: "error-page", params: { error: error } });
            this.ready = true;
            this.setBreadCrumbs();
          });
      } else {
        this.ready = true;
        this.setBreadCrumbs();
      }
    },
    setBreadCrumbs() {
      if(this.public_path) this.public_path = null;
      let _pp = {
        first: null,
        middle: null,
        second: null
      };
      // polaczenie path_object z _pp, uzycie ve
      switch (this.view) {
        case "item":
          _pp.middle = {
            title: this.$t("projects:title", "pl"),
            etitle: this.$t("projects:title", "en"),
            path: "/projects/"
          };
          this.parent.path = "/project/" + this.$route.params.project_id;
          _pp.first = this.parent;
          // problem, bo nie ciagnie odpowiednich tytulow angielskich
          _pp.second = {
            title: this.title,
            etitle: this.etitle,
            path: '/'
          };
          break;
        case "person":
          _pp.first = {
            title: this.$t("persons:title", "pl"),
            etitle: this.$t("persons:title", "en"),
            path: "/persons/"
          };
          _pp.second = {
            title: this.title,
            etitle: this.etitle,
            path: '/'
          };
          break;
        case "error-page":
          _pp.first = {
            title: this.$t("error:heading", "pl"),
            etitle: this.$t("error:heading", "en"),
            path: "/"
          };
          break;
        case "page":
          _pp.first = {
            title: this.title,
            etitle: this.etitle,
            path: '/'
          };
          break;
        case "project":
          _pp.first = {
            title: this.$t("projects:title", "pl"),
            etitle: this.$t("projects:title", "en"),
            path: "/projects/"
          };
          _pp.second = this.parent;
          break;
        case "projects":
          _pp.first = {
            title: this.$t("projects:title", "pl"),
            etitle: this.$t("projects:title", "en"),
            path: "/projects/"
          };
          break;
        case "persons":
          _pp.first = {
            title: this.$t("persons:title", "pl"),
            etitle: this.$t("persons:title", "en"),
            path: "/persons/"
          };
          break;
        case "search":
          _pp.first = {
            title: this.$t("search:title", "pl") + " : " + this.$route.params.search_query,
            etitle: this.$t("search:title", "en") + " : " + this.$route.params.search_query,
            path: "/search/" + this.$route.params.search_query
          };
          // _pp.second = {
          //   title: this.$route.params.search_query,
          //   etitle: this.$route.params.search_query,
          //   path: "/search/" + this.$route.params.search_query
          // };
          break;
        default:
          _pp.second = null;
      }
      this.public_path = _pp;
      //console.log(this.public_path);
    }
  },
};
</script>
