<template>
  <main id="main" class="page-collection">
    <bread-crumbs
      :title="'Nic nie znaleziono'"
      :etitle="'Nothing found'"
      :breadcrumbs="true"
    />
    <div class="project-viewer">
      <div class="project-image">
        <a href="javascript:void(0);">
          <div
            :style="
              'background-image:url(' +
              require('@/assets/gfx/covers/nothing.jpg') +
              ');'
            "
          ></div>
        </a>
      </div>
    </div>
  </main>
</template>
<script>
// eslint-disable-next-line
import BreadCrumbs from "@/components/BreadCrumbs.vue";
export default {
  name: "NotFoundComponent",
  data: function () {
    return {};
  },
  mounted: function () {
    //this.$emitter.emit("openSearch", {});
  },
  methods: {},
  components: {
    BreadCrumbs,
  },
};
</script>
