<template>
  <div class="project-meta project-meta-navigation">
    <div class="item-arrows" @click.prevent="prev()">
      <a href="#" v-if="!progress.reach_start"
        ><img src="@/assets/gfx/arrow.svg"
      /></a>
    </div>
    <div class="project-meta-content">
      <router-link to="/" v-if="this.$route.name == 'contest'">
        <span v-if="this.$i18n.locale == 'en'">{{ etitle }}</span>
        <span v-if="this.$i18n.locale == 'pl'">{{ title }}</span>
      </router-link>
      <router-link
        :to="'/project/' + this.$route.params.project_id + '/' + id"
        v-else
      >
        <span v-if="this.$i18n.locale == 'en'">{{ etitle }}</span>
        <span v-if="this.$i18n.locale == 'pl'">{{ title }}</span>
      </router-link>
    </div>
    <div class="item-arrows" @click.prevent="next()">
      <a href="#" v-if="!progress.reach_end"
        ><img src="@/assets/gfx/arrow.svg"
      /></a>
    </div>
  </div>
</template>
<script>
export default {
  name: "ProjectNavigation",
  props: ["title", "etitle", "id", "progress"],
  data: function () {
    return {
      data: null,
      nav: {
        prev: false,
        next: false,
      },
    };
  },
  mounted: function () {
    this.setArrows();
  },
  methods: {
    setArrows() {
      if (this.progress.slides > 1) {
        this.nav.prev = true;
        this.nav.next = true;
      }
    },
    prev() {
      this.$emitter.emit("projectSlidePrev", {});
    },
    next() {
      this.$emitter.emit("projectSlideNext", {});
    },
  },
};
</script>
