<template>
  <div class="aside-metadata aside-single">
    <div class="objects-wrapper">
      <p class="uppercase"></p>
    </div>
  </div>
</template>
<script>
/* eslint-disable */

// zmienic
export default {
  name: "AsideError",
  components: {},
  data: function () {
    return {
      active: false
    };
  },
  mounted() {},
  methods: {
    toggleSidebar() {
      this.$emitter.emit("toggleSidebar", {});
      this.active = !this.active;
    }
  }
};
</script>
