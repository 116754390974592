<template>
  <basic
    :items="items"
    :title="title"
    :aside="aside"
    :main="main"
    :project="project"
    :metadata="metadata"
    :contests="contests"
    :breadcrumbs="breadcrumbs"
    v-if="items"
  />
</template>
<script>
/* eslint-disable */
// common scripts

import httpClient from "@/scripts/httpClient";
import findTranslatedData from "@/scripts/findTranslatedData";
import iterateMetadataFields from "@/scripts/iterateMetadataFields";
import { bodyTag } from "@/scripts/bodyTag";

// base component
import Basic from "@/components/Basic.vue";

/* eslint-disable */
export default {
  name: "ContestView",
  components: {
    Basic
  },
  data: function () {
    return {
      data: null,
      items: null,
      project: null,
      metadata: null,
      contests: null,
      aside: "aside-contest",
      main: "main-project",
      title: this.customConfig.PAGE_TITLES.HOME,
      breadcrumbs: null,
    };
  },
  created: function () {
    this.sendReq();
    bodyTag(this.$route.name);
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.breadcrumbs = {};
      vm.breadcrumbs.from = from;
    });
  },
  methods: {
    sendReq() {
      this.$Progress.start();
      httpClient(
        this.customConfig.API_PATH +
          "/items?property[0][joiner]=and&property[0][property]=36&property[0][type]=res&property[0][text]=" +
          this.$route.params.contest_id +
          "&sort_by=created&sort_order=desc"
      ).then((resp) => {
        this.data = resp.data;
        this.setUpSlides();
      }).catch((error) => {
          this.$router.push({ name: "error-page", params: { error: error } });
        });
      httpClient(
        this.customConfig.API_PATH + "/items/" + this.$route.params.contest_id
      ).then((resp) => {
        this.project = resp.data;
        this.buildContestsData();
        this.buildMetadataSet();
      }).catch((error) => {
          this.$router.push({ name: "error-page", params: { error: error } });
        });
    },
    buildContestsData() {
      let _contests = [];
      if (
        typeof this.project["dcterms:references"] !== "undefined" &&
        this.project["dcterms:references"].length > 0
      ) {
        this.project["dcterms:references"].forEach((c) => {
          httpClient(
            this.customConfig.API_PATH + "/items/" + c.value_resource_id
          ).then((resp) => {
            _contests.push({
              title: resp.data["o:title"],
              etitle: findTranslatedData("dcterms:title", resp.data),
              id: resp.data["o:id"]
            });
            if (_contests.length == this.project["dcterms:references"].length) {
              this.contests = _contests;
            }
          }).catch((error) => {
          this.$router.push({ name: "error-page", params: { error: error } });
        });
        });
      }
    },
    setUpSlides() {
      // pls use http component

      this.items = [];
      this.data.forEach((e) => {
        this.items.push({
          id: e["o:id"],
          title: e["o:title"],
          etitle: findTranslatedData("dcterms:title", e),
          uri: "/",
          cover: e["thumbnail_display_urls"].large,
          thumbnail: e["thumbnail_display_urls"].medium
        });
      });


      let _this = this;

      setTimeout(function () {
        _this.$Progress.finish();
      }, 100);
    },
    buildMetadataSet() {
      let _metadata = {
        title: this.project["o:title"],
        etitle: findTranslatedData("dcterms:title", this.project),
        fields: iterateMetadataFields(this.project),
        network: null,
      };
      this.metadata = _metadata;
    }
  }
};
</script>
<style scoped></style>
