export default function getAlternativeTitle(l = "pl", i) {
  // i : item data
  let m = null;
  ////console.log(i);
  if (typeof i["dcterms:title"] !== "undefined") {
    m = i["dcterms:title"][0]["@value"];
  }
  if (typeof i["dcterms:alternative"] !== "undefined") {
    m = i["dcterms:alternative"].find((t) => t["@language"] == l);
    if (m) {
      m = m["@value"];
    } else {
      m = i["dcterms:alternative"][0]["@value"];
    }
  }
  return m;
}
