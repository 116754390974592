<template>
  <main id="main" class="page-collection page-error">
    <bread-crumbs :title="'Błąd!'" :etitle="'Error!'" :breadcrumbs="null" />
    <div class="project-viewer">
      <div class="project-image"></div>
      <div class="project-slider swiper"></div>
    </div>
  </main>
  <div class="error-container">
    <span>{{ $t("error:heading") }}</span>
    <span v-if="this.$route.params.error">
      {{ this.$route.params.error }}
    </span>
    <span v-else>
      {{ $t("error:404") }}
    </span>
  </div>
</template>
<script>
// eslint-disable-next-line
import BreadCrumbs from "@/components/BreadCrumbs.vue";
export default {
  name: "ErrorComponent",
  props: ["error"],
  data: function () {
    return {};
  },
  components: {
    BreadCrumbs,
  },
  mounted: function () {
    //console.log(this.error);
  },
  methods: {},
};
</script>
