<template>
  <div class="aside-metadata aside-person" v-if="person">
    <h1>
      <span class="uppercase" v-if="this.$i18n.locale == 'pl'">{{
        person.title
      }}</span>
      <span class="uppercase" v-if="this.$i18n.locale == 'en'">{{
        person.etitle
      }}</span>
    </h1>
    <div class="objects-wrapper">
      <div
        class="person-data"
        v-html="person.description"
        v-if="this.$i18n.locale == 'pl'"
      />
      <div
        class="person-data"
        v-html="person.edescription"
        v-if="this.$i18n.locale == 'en'"
      />
      <span
        class="page-logos"
        v-if="
          $route.params.page_slug === 'o_repozytorium' ||
          $route.params.page_slug === 'about'
        "
      >
        <a href="https://inicjatywadoskonalosci.uw.edu.pl/" target="_blank">
          <img src="/logos/idub.svg" />
        </a>
      </span>
    </div>
  </div>
</template>
<script>
// zmienic
export default {
  name: "AsidePage",
  props: ["person"],
  data: function () {
    return {
      active: false,
    };
  },
  mounted: function () {},
  methods: {
    toggleSidebar() {
      this.$emitter.emit("toggleSidebar", {});
      this.active = !this.active;
    },
  },
};
</script>
