<template>
  <div class="mobile-header">
    <h1 class="site-title"><router-link to="/">RE-CREATIO</router-link></h1>
    <div class="desktop-nav">
      <a
        href="javascript:void(0);"
        @click="toggleSearch"
        class="toggle-menu toggle-searchbar js-toggle-search"
        ><i class="icon-search"></i
      ></a>
      <locale-changer />
      <a
        @click="toggleMenu"
        href="javascript:void(0);"
        class="toggle-menu js-toggle-menu"
        ><i class="icon-menu_repo"></i
      ></a>
    </div>
  </div>
</template>
<script>
import LocaleChanger from "@/components/LocaleChanger.vue";
export default {
  name: "MobileHeader",
  components: {
    LocaleChanger,
  },
  data() {
    return {
      showMenu: true,
      showSearch: false,
      query: null,
    };
  },
  watch: {
    $route() {
      document.querySelector("body").removeAttribute("menu");
    },
  },
  methods: {
    toggleMenu() {
      if (
        !document
          .querySelector("aside .main-nav.menu-wrap")
          .classList.contains("active")
      ) {
        //document.querySelector("body").setAttribute("menu", "active");
      } else {
        //document.querySelector("body").removeAttribute("menu");
      }
      this.$emitter.emit("openMenu", {});
      //this.showSearch = false;
      //document.querySelector("body").setAttribute("route", n);
      this.showMenu = !this.showMenu;
    },
    toggleSearch() {
      this.$emitter.emit("openSearch", {});
      this.showMenu = false;
      this.showSearch = !this.showSearch;
    },
  },
};
</script>
<style scoped></style>
