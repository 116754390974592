<template>
  <main id="main" class="page-collection">
    <bread-crumbs :title="title" :etitle="etitle" :breadcrumbs="breadcrumbs" />
    <div class="project-viewer" v-if="!items">
      <div class="project-image" v-if="project"></div>
      <div class="project-meta" />
      <div class="project-slider swiper" />
    </div>
    <div class="project-viewer" v-else>
      <div class="project-image" v-if="this.$route.name != 'contest'">
        <router-link
          :to="'/project/' + this.$route.params.project_id + '/' + id"
          v-if="id"
        >
          <div :style="'background-image:url(' + image + ');'"></div>
        </router-link>
        <router-link :to="'/project/' + this.$route.params.project_id" v-else>
          <div :style="'background-image:url(' + image + ');'"></div>
        </router-link>
      </div>
      <div class="project-image" v-else>
        <router-link :to="'/project/' + id" v-if="id">
          <div :style="'background-image:url(' + image + ');'"></div>
        </router-link>
        <router-link :to="'/project/'" v-else>
          <div :style="'background-image:url(' + image + ');'"></div>
        </router-link>
      </div>
      <project-navigation
        :title="title"
        :etitle="etitle"
        :id="id"
        :progress="progress"
        v-if="ready"
      />
      <div class="project-slider swiper" ref="swiper">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(item, i) in this.items"
            v-bind:key="item.id"
            :slideid="i"
          >
            <div
              v-if="null"
              @click.prevent="
                (this.image = item.cover),
                  (this.etitle = item.etitle),
                  (this.title = item.title),
                  (this.id = item.id),
                  (this.progress.current_slide = i)
              "
              :slideid="item.id"
            >
              <img :src="item.cover" />
            </div>
            <router-link
              :to="`/project/${this.$route.params.project_id}/${item.id}`"
            >
              <div>
                <img :src="item.cover" />
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import BreadCrumbs from "@/components/BreadCrumbs.vue";
import ProjectNavigation from "@/components/ProjectNavigation.vue";
import { Swiper, Mousewheel } from "swiper"; // eslint-disable-line
import "swiper/css";
import imagesLoaded from "imagesloaded";
Swiper.use([Mousewheel]);
// linki w title dla contest moga nie dzialac :()
// zmienic
export default {
  name: "ProjectComponent",
  props: ["items", "project", "ready", "breadcrumbs"],
  components: {
    BreadCrumbs,
    ProjectNavigation,
  },
  data: function () {
    return {
      image: null,
      title: null,
      etitle: null,
      id: null,
      progress: null,
    };
  },
  mounted: function () {
    if (this.items) {
      this.setUpImage();
      this.progress = {
        slides: this.items.length,
        reach_start: true,
        reach_end: false,
        active_index: 0,
        real_index: 0,
      };
      //if (this.items.length > 1) this.progress.reach_end = false;
    }
  },
  watch: {
    ready: {
      deep: true,
      handler(r) {
        if (r) {
          this.image = this.items[0].cover;
          this.title = this.items[0].title;
          this.etitle = this.items[0].etitle;
          this.id = this.items[0].id;
          this.setUpSlider();
        }
      },
    },
  },
  methods: {
    setUpImage() {
      this.image = this.items[0].cover;
      this.title = this.items[0].title;
      this.etitle = this.items[0].etitle;
      this.id = this.items[0].id;
    },
    setUpSlider() {
      let swiper = new Swiper(this.$refs.swiper, {
        direction: "horizontal",
        init: false,
        autoHeight: false,
        slidesPerView: "auto",
        centeredSlides: false,
        centeredSlidesBounds: true,
        initialSlide: 0,
        mousewheel: true,
        freemode: true,
        slidesPerGroup: 1,
      });

      let _this = this;
      imagesLoaded(this.$refs.swiper, () => {
        _this.progress = {
          slides: _this.items.length,
          reach_start: true,
          reach_end: false,
          active_index: 0,
          real_index: 0,
        };
        swiper.init();
        swiper.slideTo(0);
        swiper.snapGrid[swiper.snapGrid.length - 1] =
          swiper.slidesGrid[swiper.slidesGrid.length - 1];
      });
      let _p = this.progress;
      let _setItem = function (ri) {
        _this.image = _this.items[ri].cover;
        _this.title = _this.items[ri].title;
        _this.etitle = _this.items[ri].etitle;
        _this.id = _this.items[ri].id;
        // change active class
        if (_this.$refs.swiper) {
          let ns = _this.$refs.swiper.querySelectorAll(".swiper-slide");
          ns.forEach((e) => {
            e.classList.remove("active-swiper-slide");
          });
          ns[ri].classList.add("active-swiper-slide");
        }
      };
      this.$emitter.on("projectSlidePrev", () => {
        swiper.slidePrev();
        if (_p.real_index - 1 >= 0) _p.real_index--;
        _p.active_index = swiper.activeIndex;
        _this.progress.reach_end = false;
        if (_p.real_index == 0) {
          _this.progress.reach_start = true;
        }
        _setItem(_p.real_index);
        ////console.log([swiper.activeIndex, _p.active_index, _p.real_index]);
      });
      this.$emitter.on("projectSlideNext", () => {
        swiper.slideNext();
        if (_p.real_index < _this.items.length - 1) _p.real_index++;
        _this.progress.reach_start = false;
        if (_p.real_index == _this.items.length - 1) {
          _this.progress.reach_end = true;
        }
        _p.active_index = swiper.activeIndex;
        _setItem(_p.real_index);
      });

      swiper.on("click", function (c, e) {
        // deprecated
        if (e.target.nodeName == "IMG") {
          let imgs = _this.$refs.swiper.querySelectorAll("img");
          let ns = _this.$refs.swiper.querySelectorAll(".swiper-slide");
          ns.forEach((e) => {
            e.classList.remove("active-swiper-slide");
          });
          imgs.forEach((e) => {
            e.parentNode.classList.remove("active-image");
          });
          e.target.parentNode.classList.add("active-image");
          let n = c.clickedSlide;
          n.classList.add("active-swiper-slide");
          // //console.log([c, e, _this.id]);
          // fck
        }
      });
    },
  },
};
</script>
<style scoped></style>
