<template>
  <div class="aside-metadata aside-single">
    <div class="metadata-list" v-if="metadata">
      <span class="metadata-header" v-if="metadata.network.creators.length > 0">
        <ul>
          <li v-for="(author, index) in metadata.network.creators" :key="index">
            <router-link :to="'/person/' + author.id">{{
              author.name
            }}</router-link>
          </li>
        </ul>
      </span>
    </div>
    <h1 v-if="metadata.title.length > 0" class="test">
      <span class="uppercase" v-if="this.$i18n.locale == 'en'">{{
        metadata.etitle
      }}</span>
      <span class="uppercase" v-else>{{ metadata.title }}</span>
    </h1>
    <div class="objects-wrapper">
      <p class="uppercase" v-if="items">
        {{ $t("project:contains") }} {{ this.items.length }} {{ this.sheets }}.
      </p>
      <div class="contests-list" v-if="contests">
        {{ $t("project:contests_info") }}:
        <div>
          <router-link
            v-for="(c, index) in contests"
            :key="index"
            :to="'/contest/' + c.id"
          >
            <span v-if="this.$i18n.locale == 'pl'">{{ c.title }}</span>
            <span v-if="this.$i18n.locale == 'en'">{{ c.etitle }}</span>
          </router-link>
        </div>
      </div>
      <metadata-widget :metadata="metadata" />
    </div>
  </div>
</template>
<script>
import MetadataWidget from "@/components/Asides/Metadata/MetadataWidget.vue";
import { polishPlurals } from "polish-plurals";
/* eslint-disable */
import plT from "@/scripts/plT";

// zmienic
export default {
  name: "AsideProject",
  props: ["project", "metadata", "items", "contests"],
  components: {
    MetadataWidget
  },
  data: function () {
    return {
      active: false,
      sheets: null
    };
  },
  mounted() {
    if (this.items) this.countSheets();
    //let s = plT(12);
    let _this = this;
    if (this.$route.name == "project") {
      this.$emitter.on("localeChanged", () => {
        if (_this.items) _this.countSheets();
      });
    }
  },
  watch: {
    items: {
      deep: true,
      handler(items) {
        //if (items.length) this.countSheets();
        //console.log(items.length);
      }
    }
  },
  methods: {
    plT,
    countSheets() {
      if (this.$route.name == "project" && this.$i18n.locale) {
        if (this.$i18n.locale == "pl") {
          this.sheets = polishPlurals(
            this.$t("project:sheet"),
            this.$t("project:sheets"),
            this.$t("project:more-sheets"),
            this.items.length
          );
        } else {
          this.sheets = polishPlurals(
            this.$t("project:sheet"),
            this.$t("project:sheets"),
            this.$t("project:sheets"),
            this.items.length
          );
        }
      }
    },
    toggleSidebar() {
      this.$emitter.emit("toggleSidebar", {});
      this.active = !this.active;
    }
  }
};
</script>
