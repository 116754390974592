<template>
  <div class="item-arrows-container" v-if="left || right">
    <div class="item-arrows">
      <div>
        <router-link :to="left.path" v-if="left" :title="$t('nav:prev')">
          <img src="@/assets/gfx/arrow.svg" />
        </router-link>
      </div>
      <div class="item-arrows-desc" v-if="left || right">
        <span
          >{{ $t("items:nav") }} ({{ this.index + 1 }} /
          {{ this.context.length }})</span
        >
      </div>
      <div>
        <router-link :to="right.path" v-if="right" :title="$t('nav:next')">
          <img src="@/assets/gfx/arrow.svg" />
        </router-link>
      </div>
    </div>
  </div>
  <div class="item-arrows-container" v-else />
</template>
<script>
import httpClient from "@/scripts/httpClient";
//import findTranslatedData from "@/scripts/findTranslatedData";
export default {
  name: "ItemNavigation",
  props: ["context"],
  data: function () {
    return {
      left: null,
      right: null,
      page: 1,
      count: null,
      items: null,
      index: null,
    };
  },
  mounted: function () {
    //console.log(this.item);
    //this.sendReq();
    this.setUpArrows2();
  },
  methods: {
    setUpArrows2() {
      if (typeof this.context !== "undefined") {
        let itms = [];

        this.context.forEach((itm) => {
          let id = itm["@id"].split("/");
          //console.log(id[id.length - 1]);

          httpClient(this.customConfig.API_PATH + "/items/" + id[id.length - 1])
            .then((resp) => {
              let order = id[id.length - 1];

              //console.log(resp.data);

              if (typeof resp.data["curation:rank"] !== "undefined") {
                order = resp.data["curation:rank"][0]["@value"];
              }

              itms.push({
                id: id[id.length - 1],
                order: order,
                project_id: this.$route.params.project_id,
                path:
                  "/project/" +
                  this.$route.params.project_id +
                  "/" +
                  id[id.length - 1],
              });

              if (typeof resp.data["curation:rank"] !== "undefined") {
                itms.sort((a, b) => a.order - b.order);
              } else {
                itms.sort((a, b) => b.order - a.order);
              }

              if (itms.length == this.context.length) {
                let _this = this;
                //console.log("end");
                this.index = itms.findIndex(
                  (item) => item.id == _this.$route.params.item_id
                );
                //console.log("index: " + this.index);

                if (typeof itms[this.index - 1] !== "undefined") {
                  this.left = itms[this.index - 1];
                }
                if (typeof itms[this.index + 1] !== "undefined") {
                  this.right = itms[this.index + 1];
                }

                //console.log([this.left, this.right, itms]);
              }

              //console.log([itms.length, i++]);
            })
            .catch((error) => {
              console.log(error);
              // pls do nothing
              //this.$router.push({ name: "error-page", params: { error: error } });
            })
            .finally(() => {});
        });

        //console.log(itms);
      }
    },
  },
};
</script>
