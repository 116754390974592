<template>
  <h1 class="site-title">RE-CREATIO</h1>
  <div id="slider" ref="slider" :key="key"></div>
  <i class="icon-arrows" v-if="null"></i>
</template>
<script>
import { toRaw } from "vue";
import noUiSlider from "nouislider";
import wNumb from "wnumb";
require("@/assets/nouislider.min.css");
require("@/assets/nouislider-style-vue.css");

export default {
  name: "AsideSlider",
  data: function () {
    return {
      active: false,
      slider: null,
      direction: "horizontal",
      items: null,
      key: true,
    };
  },
  mounted: function () {
    this.$emitter.on("swiperReady", (items) => {
      let _this = this;
      this.items = items;

      if (this.$refs.slider) {
        // set default orientation
        if (
          window.innerHeight <= 740 &&
          window.matchMedia("(orientation: landscape)").matches
        ) {
          this.direction = "horizontal";
        } else {
          this.direction = "vertical";
        }

        if (
          window.innerWidth >= 746 &&
          window.innerWidth <= 1080 &&
          window.matchMedia("(orientation: landscape)").matches
        ) {
          this.direction = "vertical";
        }

        if (
          window.innerHeight <= 480 &&
          window.matchMedia("(orientation: landscape)").matches
        ) {
          _this.direction = "horizontal";
        }

        // set up slider
        this.setUpSlider(this.items);

        // events

        window.addEventListener("resize", function () {
          _this.slider.noUiSlider.destroy();
          if (
            window.innerHeight <= 740 &&
            window.matchMedia("(orientation: landscape)").matches
          ) {
            _this.direction = "horizontal";
          } else {
            _this.direction = "vertical";
          }
          // ipad fix
          if (
            window.innerWidth >= 746 &&
            window.innerWidth <= 1080 &&
            window.matchMedia("(orientation: landscape)").matches
          ) {
            _this.direction = "vertical";
          }
          // komórka landscape fix
          if (
            window.innerHeight <= 480 &&
            window.matchMedia("(orientation: landscape)").matches
          ) {
            _this.direction = "horizontal";
          }
          _this.setUpSlider(_this.items);
          //_this.direction = "vertical";
        });

        // orientation change
        window.addEventListener("orientationchange", function () {
          if (_this.slider) {
            _this.slider.destroy();
            _this.setUpSlider(_this.items);
          }
        });
      }
    });
  },
  methods: {
    toggleSidebar() {
      this.$emitter.emit("toggleSidebar", {});
      this.active = !this.active;
    },
    setUpSlider(items) {
      //https://stackoverflow.com/a/65739833
      let i = JSON.parse(JSON.stringify(toRaw(items)));

      let scale = [];

      i.items.forEach((e) => {
        scale.push(Number(e.date));
        scale.push(Number(e.date2));
      });

      let slider = this.$refs.slider;
      let _this = this;
      noUiSlider.create(slider, {
        start: [Math.min(...scale) + 0, Math.max(...scale) - 0],
        // range: {
        //   min: Math.min(...scale),
        //   max: Math.max(...scale),
        // },
        connect: true,
        range: {
          min: [Math.min(...scale)],
          max: [Math.max(...scale)],
        },
        orientation: _this.direction,
        behaviour: "tap-drag",
        tooltips: true,
        format: wNumb({ decimals: 0 }),
      });
      this.slider = slider;

      slider.noUiSlider.on("change", function (values) {
        _this.$emitter.emit("filtersChanged", { values });
      });
    },
  },
};
</script>
