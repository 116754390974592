<template>
  <a @click="toggleLang" href="javascript:void(0);" class="lang" v-if="true"
    ><i class="icon-box"></i><span v-html="lang"></span
  ></a>
</template>
<script>
import httpClient from "@/scripts/httpClient";
export default {
  name: "LocaleChanger",
  data() {
    return { pl: null, lang: null };
  },
  mounted: function () {
    //console.log("mounted");
    if (this.$i18n.locale == "pl") {
      this.lang = "pl";
      this.$i18n.locale = "en";
    } else {
      this.lang = "en";
      this.$i18n.locale = "pl";
    }
    let _this = this;
    this.$emitter.on("changeLocaleForPage", (lang) => {
      //console.log("event zauwazony");
      _this.changeSystemLanguage(lang);
    });
  },
  methods: {
    changeSystemLanguage(lang) {
      this.lang = lang;
      //this.$emitter.emit("localeChanged");
    },
    moveToPage(_id) {
      httpClient(this.customConfig.API_PATH + "/site_pages/" + _id)
        .then((resp) => {
          let _slug = resp.data["o:slug"];
          this.$router.push("/page/" + _slug);
        })
        .catch((error) => {
          console.log(error);
          // do nothing
        });
    },
    checkPageTranslation() {
      httpClient(
        this.customConfig.API_PATH +
          "/site_pages?slug=" +
          this.$route.params.page_slug
      )
        .then((resp) => {
          // SET LANG

          // if (typeof resp.data[0]["o:site"] !== "undefined") {
          //   let _s = resp.data[0]["o:site"];
          //   if (_s["o:id"] == 2) {
          //     this.lang = "en";
          //     this.$i18n.locale = "pl";
          //   } else {
          //     this.lang = "pl";
          //     this.$i18n.locale = "en";
          //   }
          //   this.$emitter.emit("localeChanged");
          // }

          //console.log(resp);
          let _blocks = resp.data[0]["o:block"];
          let _tp;
          //console.log(_blocks);

          if (typeof _blocks !== "undefined") {
            _tp = _blocks.find((b) => b["o:layout"] == "mirrorPage");
            //console.log(_tp);

            if (_tp) {
              if (typeof _tp["o:data"].page !== undefined) {
                this.moveToPage(_tp["o:data"].page);
              }
            } else {
              // SET LANG
              // if (typeof resp.data[0]["o:site"] !== "undefined") {
              //   let _s = resp.data[0]["o:site"];
              //   if (_s["o:id"] == 2) {
              //     this.lang = "en";
              //     this.$i18n.locale = "pl";
              //   } else {
              //     this.lang = "pl";
              //     this.$i18n.locale = "en";
              //   }
              //   this.$emitter.emit("localeChanged");
              // }
            }
          }
        })
        .catch((error) => {
          console.log(error);
          // do nothing
        });
    },
    toggleLang() {
      //this.pl = !this.pl;
      if (this.$i18n.locale == "pl") {
        this.lang = "pl";
        this.$i18n.locale = "en";
      } else {
        this.lang = "en";
        this.$i18n.locale = "pl";
      }
      this.$emitter.emit("localeChanged");
      if (this.$route.name == "page") {
        this.checkPageTranslation();
      }
    },
  },
};
</script>
