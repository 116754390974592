export default function iterateMedatadaFields(i) {
  // try to get onlu metadata values
  // they are preent within objects with property_id
  let _m = [];
  let _a = JSON.parse(JSON.stringify(i));
  let _v = Object.values(_a);
  _v.forEach((e) => {
    if (Array.isArray(e) && e.length > 0) {
      if (Object.hasOwn(e[0], "property_id")) _m.push(e);
    }
  });
  let s = [];
  _m.forEach((_f) => {
    // _f[0]["property_id"] != 17 filters alternative title
    if (
      _f[0]["property_id"] &&
      _f[0]["property_id"] != 17 &&
      _f[0]["property_id"] != 33
    ) {
      // nie trzeba bo parsujemy wszystko
      let _g = {
        property_id: _f[0].property_id,
        type: _f[0].type,
        values: [],
      };

      _f.forEach((v) => {
        if (v["type"] == "literal") {
          let _l = v["@language"];
          if (typeof _l === "undefined") _l = "pl";
          _g.values.push({
            property_label: v.property_label,
            "@value": v["@value"],
            "@id": null,
            "@language": _l,
          });
        } else if (v["type"] == "uri") {
          // public domain
          let _l = v["o:lang"];
          if (typeof _l == "undefined") _l == "pl";
          _g.values.push({
            property_label: v.property_label,
            "@value": v["o:label"],
            "@id": v["@id"],
            "@language": _l,
          });
        } else if (v["value_resource_id"]) {
          // "type": "resource:item",
        }
      });
      // na brak tlumaczenia en
      if (!_g.values.some((v) => v["@language"] == "en")) {
        if (_g["type"] == "uri" || _g["type"] == "literal") {
          _g.values.push({
            property_label: _g.values[0].property_label,
            "@value": _g.values[0]["@value"],
            "@id": _g.values[0]["@id"],
            "@language": "en",
          });
        }
      }
      s.push(_g);
    }
  });
  //console.log(s);
  return s;
}
