<template>
  <div class="aside-metadata aside-person" v-if="person">
    <h1>
      <span class="uppercase" v-if="this.$i18n.locale == 'pl'">{{
        person.title
      }}</span>
      <span class="uppercase" v-if="this.$i18n.locale == 'en'">{{
        person.etitle
      }}</span>
    </h1>
    <div class="objects-wrapper">
      <div
        class="person-data"
        v-html="person.description"
        v-if="this.$i18n.locale == 'pl'"
      />
      <div
        class="person-data"
        v-html="person.edescription"
        v-if="this.$i18n.locale == 'en'"
      />
      <metadata-widget :metadata="person.metadata" />
      <div
        v-if="person.network.nodes.filter((v) => v.type == 'project').length"
        class="metadata-list"
      >
        <ul>
          <li>
            <div>
              <div class="metadata-field">
                <div>{{ $t("projects:title") }}</div>
                <div v-if="this.$i18n.locale == 'en'">
                  <span
                    v-for="(project, index) in person.network.nodes.filter(
                      (v) => v.type == 'project'
                    )"
                    :key="index"
                  >
                    <router-link :to="'/project/' + project.id">
                      {{ project.elabel }}
                    </router-link></span
                  >
                </div>
                <div v-if="this.$i18n.locale == 'pl'">
                  <span
                    v-for="(project, index) in person.network.nodes.filter(
                      (v) => v.type == 'project'
                    )"
                    :key="index"
                  >
                    <router-link :to="'/project/' + project.id">
                      {{ project.label }}
                    </router-link></span
                  >
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import MetadataWidget from "@/components/Asides/Metadata/MetadataWidget.vue";
export default {
  name: "AsidePerson",
  props: ["person"],
  data: function () {
    return {
      active: false,
    };
  },
  components: {
    MetadataWidget,
  },
  methods: {
    toggleSidebar() {
      this.$emitter.emit("toggleSidebar", {});
      this.active = !this.active;
    },
  },
};
</script>
<style scoped>
.metadata-field div:first-of-type {
  text-transform: uppercase;
}
</style>
