<template>
  <basic :title="title" :main="main" :aside="aside" />
</template>
<script>
// base component
import Basic from "@/components/Basic.vue";
import { bodyTag } from "@/scripts/bodyTag";

/* eslint-disable */
export default {
  name: "ErrorView",
  props: ["error"],
  components: {
    Basic,
  },
  data: function () {
    return {
      title: "Error",
      main: "main-error",
      aside: "aside-error",
      //error: this.$route.params.error,
    };
  },
  mounted: function () {
    bodyTag(this.$route.name);
    this.setUpError();
  },
  methods: {
    setUpError() {
      //console.log(this.$route.params.error);
    }
  }
};
</script>
<style scoped></style>
