<template>
  <a
    href="javascript:void(0);"
    class="toggle-sidebar js-toggle-sidebar"
    :class="{ active: active }"
    @click="toggleSidebar"
    ><span>&lt;</span></a
  >
</template>
<script>
// zmienic
export default {
  name: "ToggleSidebarButton",
  data: function () {
    return {
      active: false,
    };
  },
  methods: {
    toggleSidebar() {
      this.$emitter.emit("toggleSidebar", {});
      this.active = !this.active;
    },
  },
};
</script>
