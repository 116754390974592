<template>
  <div class="suggestion-not-found" v-if="nothing">
    {{ $t("search:nothing_found") }}
  </div>
  <div class="suggestion-not-found" v-if="!finished && !nothing">
    {{ $t("loading_messsage") }}
  </div>
  <div class="suggestion-widget" v-if="suggestions && suggestions.length > 0">
    <ul :class="{ notsomuch: suggestions.length < 10 }">
      <li v-for="(s, index) in suggestions" :key="index">
        <router-link :to="s.uri" v-if="s.uri" class="suggestion">
          <span v-if="this.$i18n.locale == 'en'">{{ s.etitle }}</span>
          <span v-if="this.$i18n.locale == 'pl'"> {{ s.title }}</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>
<script>
import httpClient from "@/scripts/httpClient";
//import findTranslatedData from "@/scripts/findTranslatedData";

export default {
  name: "SearchSuggest",
  data() {
    return {
      page: 1,
      count: null,
      data: [],
      nothing: null,
      suggestions: [],
      finished: true,
      query: null,
      controller: false,
      stop: false,
    };
  },
  mounted: function () {
    let _this = this;
    this.$emitter.on("suggestResults", function (e) {
      _this.resetQuery();
      _this.searchSuggest(e);
    });
  },
  watch: {
    data: {
      deep: true,
      handler() {
        if (this.count && !this.stop) {
          if (this.data.length !== this.count) {
            this.page++;
            if (this.page < 130) {
              this.sendReq();
            }
          } else {
            this.finished = true;
          }
        } else {
          this.finished = true;
        }
      },
    },
  },
  methods: {
    resetQuery() {
      if (this.controller) this.controller.abort();
      this.stop = true;
      // stop all prev requests
      this.count = null;
      this.page = 1;
      this.data = [];
      this.nothing = null;
      this.suggestions = [];
      this.query = null;
      this.finished = true;
    },
    searchSuggest(e) {
      // clean data
      this.finished = false;
      if (e.length > 0) {
        this.query = e;
        this.stop = false;
        this.sendReq();
      }
    },
    sendReq() {
      this.$Progress.start();
      let _requri =
        this.customConfig.API_PATH +
        "/items?property[0][joiner]=and&property[0][property]=&property[0][type]=in&property[0][text]=" +
        this.query +
        "&page=" +
        this.page;
      this.controller = new AbortController();
      httpClient(_requri, { signal: this.controller.signal })
        .then((resp) => {
          this.data = this.data.concat(resp.data);
          this.count = Number(resp.headers["omeka-s-total-results"]);
          this.setUpResults(resp.data);
          this.$Progress.finish();
        })
        .catch((error) => {
          console.log(error);
          this.data = [];
        });
    },
    setUpResults(d) {
      if (this.count == 0) this.nothing = true;
      if (typeof d !== "undefined") {
        d.forEach((e) => {
          if (
            e["o:resource_template"]["o:id"] != 6 &&
            e["o:resource_template"]["o:id"] != 4
          ) {
            //let _t = findTranslatedData("dcterms:title", e);
            let _uri, _order;

            switch (e["o:resource_template"]["o:id"]) {
              case 3:
                _uri = "/person/" + e["o:id"];
                _order = 1;
                break;
              case 4:
                _uri = "/contest/" + e["o:id"];
                _order = 9999;
                break;
              case 5:
                if (e["dcterms:isPartOf"]) {
                  _uri =
                    "/project/" +
                    e["dcterms:isPartOf"][0].value_resource_id +
                    "/" +
                    e["o:id"];
                }
                _order = 3;
                break;
              default:
                _uri = "/project/" + e["o:id"];
                _order = 2;
            }

            // check for translations
            // default
            let _title = e["o:title"];
            let _etitle = e["o:title"];

            // try

            if (typeof e["dcterms:alternative"] !== "undefined") {
              if (e["dcterms:alternative"].length > 0) {
                e["dcterms:alternative"].forEach((at) => {
                  if (at["@language"] === "pl") _title = at["@value"];
                  if (at["@language"] === "en") _etitle = at["@value"];
                });
              }
            }

            this.suggestions.push({
              title: _title,
              etitle: _etitle,
              //etitle: _t ? _t : e["o:title"],
              id: e["o:id"],
              uri: _uri,
              order: _order,
            });
            this.suggestions.sort((a, b) =>
              a.order < b.order ? -1 : Number(a.order > b.order)
            );
          }
        });
      }
    },
  },
};
</script>
<style scoped></style>
