<template>
  <div class="download-modal" v-if="data" @click="closeModal()">
    <div class="download-modal-header">
      <i class="icon-close"></i>
    </div>
    <div class="download-modal-links">
      <a
        :href="data.original.uri"
        target="_blank"
        :download="data.original.filename"
        >{{ $t("item:download-full") }}</a
      >
      <span
        >{{ this.data.original.width }} / {{ this.data.original.height }}</span
      >
      <a
        :href="data.current.uri"
        target="_blank"
        :download="data.current.filename"
        >{{ $t("item:download-view") }}</a
      >
    </div>
    <div class="download-modal-rights">
      {{ $t("item:download_desc") }}
    </div>
  </div>
</template>
<script>
import httpClient from "@/scripts/httpClient";
export default {
  name: "ItemDownload",
  props: ["osd", "item"],
  data: function () {
    return {
      data: null,
    };
  },
  mounted: function () {
    this.sendReq();
  },
  methods: {
    closeModal() {
      this.$emitter.emit("closeModal");
    },
    sendReq() {
      httpClient(
        this.customConfig.API_PATH + "/media/" + this.item.media[0]["o:id"]
      )
        .then((resp) => {
          //console.log(resp.data.data.dimensions.original);

          let _data = {};
          _data.original = {};
          _data.original.width =
            resp.data.data.dimensions.original.width + " px";
          _data.original.height =
            resp.data.data.dimensions.original.height + " px";
          _data.original.uri = resp.data["o:original_url"];
          _data.original.filename = "fullsize_" + resp.data["o:filename"];

          // current view

          let _img = this.osd.drawer.canvas.toDataURL("image/jpeg");
          //_img.setAttribute("crossorigin", "anonymous");
          _data.current = {};
          _data.current.uri = _img;
          _data.current.filename = "currentview_" + resp.data["o:filename"];
          this.data = _data;
        })
        .catch((error) => {
          console.log(error);
          // pls do nothing
          //this.$router.push({ name: "error-page", params: { error: error } });
        });
    },
  },
};
</script>
