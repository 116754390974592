<template>
  <basic
    :items="null"
    :item="item"
    :title="title"
    :etitle="etitle"
    :aside="aside"
    :main="main"
    :metadata="metadata"
    :breadcrumbs="breadcrumbs"
    v-if="item && metadata"
    :key="key"
  />
</template>
<script>
/* eslint-disable */
// common scripts

import httpClient from "@/scripts/httpClient";
import iterateMetadataFields from "@/scripts/iterateMetadataFields";
import findTranslatedData from "@/scripts/findTranslatedData";
import buildPersonSet from "@/scripts/buildPersonSet";
import getAlternativeTitle from "@/scripts/getAlternativeTitle";
import { bodyTag } from "@/scripts/bodyTag";

// base component
import Basic from "@/components/Basic.vue";

/* eslint-disable */
export default {
  name: "ItemView",
  components: {
    Basic
  },
  data: function () {
    return {
      data: null,
      item: null,
      aside: "aside-item",
      main: "main-item",
      title: this.customConfig.PAGE_TITLES.HOME,
      etitle: this.customConfig.PAGE_TITLES.HOME,
      metadata: null,
      relations: null,
      breadcrumbs: null,
      project: null,
      key: true,
    };
  },
  created: function () {
    this.sendReq();
    bodyTag(this.$route.name);
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.breadcrumbs = {};
      vm.breadcrumbs.from = from;
    });
  },
  watch: {
    $route(to, from) {
      if(to.name == from.name) {
        this.key = !this.key;
        this.data = null;
        this.metadata = null;
        this.relations = null;
        // error with breadcrumbs from - set to?
        this.breadcrumbs = {
          from: {
            name: "item",
          },
        };
        this.project = null;
        this.sendReq();
      }
    }
  },
  methods: {
    sendReq() {
      this.$Progress.start();
      httpClient(
        this.customConfig.API_PATH + "/items/" + this.$route.params.item_id
      )
        .then((resp) => {
          this.data = resp.data;

          httpClient(
            this.customConfig.API_PATH +
              "/items/" +
              this.$route.params.project_id
          )
            .then((resp) => {
              this.project = resp.data;
              this.setUpSlides();
            })
            .catch((error) => {
              this.$router.push({
                name: "error-page",
                params: { error: error }
              });
            });
        })
        .catch((error) => {
          this.$router.push({ name: "error-page", params: { error: error } });
        });
    },
    buildMetadataSet() {

      let _a = [];
      _a.push(this.data);

      let _this = this;

      if (!this.metadata) {
        buildPersonSet(this.project, [this.data], function (network) {
          let _metadata = {
            title: getAlternativeTitle("pl", _this.data),
            etitle: getAlternativeTitle("en", _this.data),
            //etitle: findTranslatedData("dcterms:alternative", _this.data),
            fields: iterateMetadataFields(_this.data),
            network: network
          };
          ////console.log(_metadata);
          _this.metadata = _metadata;
          _this.title = _this.customConfig.PAGE_TITLES.HOME + ' : ' + _this.metadata.title;
          _this.etitle = _this.customConfig.PAGE_TITLES.HOME + ' : ' + _this.metadata.etitle;
        });
      }
    },
    setUpSlides() {
      // pls use http component

      let media = this.data["o:media"];
      this.item = {
        id: this.data["o:id"],
        //title: this.data["o:title"],
        title: getAlternativeTitle("pl", this.data),
        etitle: getAlternativeTitle("en", this.data),
        uri: "/",
        cover: this.data["thumbnail_display_urls"].large,
        thumbnail: this.data["thumbnail_display_urls"].medium,
        // deprec
        // iiif: [],
        media: this.data["o:media"],
        tiles: [],
        context: this.project["@reverse"]["dcterms:isPartOf"]
      };
      
      media.forEach((e,i) => {
        
        // deprec
        // this.item.iiif.push(
        //   this.customConfig.MANIFEST_PATH + e["o:id"] + "/info.json"
        // );

        httpClient(
            e["@id"]
          )
            .then((resp) => {
              //console.log([e["@id"], resp.data]);
              
              if (typeof resp.data["data"]["tile"]["zoomify"] == "undefined") {

                if (typeof resp.data["data"]["tile"]["deepzoom"] != "undefined") {
                  let t = resp.data["data"]["tile"]["deepzoom"]
                  this.item.tiles.push(
                    t["url_base"] + "/" + t["metadata_path"]
                  )

                } else {
                  console.log("error");
                  // this.$router.push({
                  //   name: "error-page",
                  //   params: { error: 'bad tile source!' }
                  // });

                }

              } else {

                let t = resp.data["data"]["tile"]["zoomify"]
              this.item.tiles.push({
                type: "zoomifytileservice",
                width: t.source.width,
                height: t.source.height,
                tilesUrl: t["url_base"] + "/" + t["media_path"] + "/"
              })

              }

              if (this.data["o:media"].length == i + 1) {
                this.buildMetadataSet();
                let _this = this;

                setTimeout(function () {
                  _this.$Progress.finish();
                }, 100);
              }
            
            })
            .catch((error) => {
              console.log(error);
              this.$router.push({
                name: "error-page",
                params: { error: error }
              });
            });

      });
      
    }
  }
};
</script>
<style scoped></style>
