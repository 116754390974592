<template>
  <MobileHeader v-if="!showAsideMobile" />
  <vertical-slider :items="items" v-if="main == 'main-slider'" />
  <not-found-component
    v-if="main == 'main-not-found'"
    :breadcrumbs="breadcrumbs"
  />
  <project-component
    :items="items"
    :ready="ready"
    :project="project"
    :breadcrumbs="breadcrumbs"
    v-if="main == 'main-project'"
  />
  <item-component
    :item="item"
    :breadcrumbs="breadcrumbs"
    v-if="main == 'main-item'"
  />
  <search-component
    :results="results"
    :breadcrumbs="breadcrumbs"
    v-if="main == 'main-search'"
    :ready="ready"
  />
  <person-component
    :person="person"
    :breadcrumbs="breadcrumbs"
    v-if="main == 'main-person'"
  />
  <error-component v-if="main == 'main-error'" :breadcrumbs="breadcrumbs" />
  <div class="line" v-if="main != 'main-error'" />
  <div class="line-error" v-else />
  <toggle-sidebar-button />
  <aside :class="{ 'aside-mobile': showAsideMobile }">
    <DefaultHeader />
    <div class="aside-content aside-flex">
      <aside-slider v-if="aside == 'aside-slider'" />
      <aside-error v-if="aside == 'aside-error'" />
      <aside-search
        v-if="aside == 'aside-search'"
        :ready="ready"
        :results="results"
      />
      <aside-not-found v-if="aside == 'aside-not-found'" />
      <aside-person
        v-if="aside == 'aside-person'"
        :items="items"
        :person="person"
      />
      <aside-page v-if="aside == 'aside-page'" :person="person" />
      <aside-project
        v-if="aside == 'aside-project'"
        :project="project"
        :metadata="metadata"
        :items="items"
        :contests="contests"
      />
      <aside-contest
        v-if="aside == 'aside-contest'"
        :project="project"
        :metadata="metadata"
        :items="items"
      />
      <aside-item
        v-if="aside == 'aside-item'"
        :item="item"
        :metadata="metadata"
      />
    </div>
    <DefaultFooter />
    <PrivacyFooter />
  </aside>
</template>

<script>
// common
import MobileHeader from "@/components/MobileHeader.vue";
import DefaultHeader from "@/components/DefaultHeader.vue";
import DefaultFooter from "@/components/DefaultFooter.vue";
import ToggleSidebarButton from "@/components/ToggleSidebarButton.vue";
import { useHead } from "@vueuse/head";
import PrivacyFooter from "@/components/PrivacyFooter.vue";

// slider & filters & main
import VerticalSlider from "@/components/Sliders/VerticalSlider.vue";
import ProjectComponent from "@/components/Content/ProjectComponent.vue";
import ItemComponent from "@/components/Content/ItemComponent.vue";
import PersonComponent from "@/components/Content/PersonComponent.vue";
import SearchComponent from "@/components/Content/SearchComponent.vue";
import ErrorComponent from "@/components/Content/ErrorComponent.vue";
import NotFoundComponent from "@/components/Content/NotFoundComponent.vue";

// asides
import AsideProject from "@/components/Asides/AsideProject.vue";
import AsidePerson from "@/components/Asides/AsidePerson.vue";
import AsideSearch from "@/components/Asides/AsideSearch.vue";
import AsideItem from "@/components/Asides/AsideItem.vue";
import AsidePage from "@/components/Asides/AsidePage.vue";
import AsideContest from "@/components/Asides/AsideContest.vue";
import AsideSlider from "@/components/Asides/AsideSlider.vue";
import AsideError from "@/components/Asides/AsideError.vue";
import AsideNotFound from "@/components/Asides/AsideNotFound.vue";

/* eslint-disable */
export default {
  name: "Basic",
  props: [
    "items",
    "results",
    "item",
    "title",
    "etitle",
    "project",
    "metadata",
    "person",
    "aside",
    "main",
    "person",
    "contests",
    "ready",
    "breadcrumbs",
  ],
  components: {
    MobileHeader,
    ToggleSidebarButton,
    DefaultHeader,
    DefaultFooter,
    VerticalSlider,
    AsideSlider,
    AsideProject,
    ProjectComponent,
    ItemComponent,
    PersonComponent,
    AsidePerson,
    SearchComponent,
    AsideSearch,
    AsideItem,
    AsidePage,
    AsideContest,
    ErrorComponent,
    AsideError,
    NotFoundComponent,
    AsideNotFound,
    PrivacyFooter,
  },
  data: function () {
    return {
      showAsideMobile: false
    };
  },
  created: function () {
    useHead({
      title: this.title,
      bodyAttrs: { id: "main" }
    });
  },
  mounted: function () {
    let _this = this;
    this.$emitter.on("toggleSidebar", () => {
      _this.showAsideMobile = !_this.showAsideMobile;
    });
    //console.log(this.$route.name);
  }
};
</script>
<style scoped></style>
