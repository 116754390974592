<template>
  <div class="aside-metadata aside-search" :key="key" v-if="results">
    <div>
      <h1>
        <span class="uppercase" v-if="this.$route.name == 'persons'">
          {{ $t("persons:title") }}
        </span>
        <span class="uppercase" v-if="this.$route.name == 'projects'">
          {{ $t("projects:title") }}
        </span>
        <span class="uppercase" v-if="this.$route.name == 'contests'">
          {{ $t("contests:title") }}
        </span>
        <span class="uppercase" v-if="this.$route.name == 'search'">
          {{ $t("search:title") }} : {{ this.$route.params.search_query }}
        </span>
      </h1>
    </div>
    <div class="objects-wrapper swiper" ref="swiper">
      <div class="swiper-wrapper">
        <div
          v-for="(result, index) in results"
          :key="index"
          class="result swiper-slide"
          :slide-id="index"
        >
          <div
            @click="
              ((currentItem = result), (this.index = index)),
                changeCurrentItem()
            "
            v-if="result.type_id != 6"
            :class="{ selected: this.index == index }"
          >
            <span v-if="this.$i18n.locale === 'pl'">{{ result.title }}</span>
            <span v-if="this.$i18n.locale === 'en'">{{ result.etitle }}</span>
          </div>
          <div
            :class="{ selected: this.index == index }"
            @click="
              ((currentItem = result), (this.index = index)),
                changeCurrentItem()
            "
            v-else
          >
            {{ result.relations[0].agent_title }}
            &nbsp;|&nbsp;
            {{ result.relations[0].item_title }}
          </div>
          <router-link :to="result.uri" v-if="result.type_id != 6">
            <img src="@/assets/gfx/drawingc.svg" class="direct-link" />
          </router-link>
          <a href="#" v-else>
            <img
              @click="linkCurrentItem(result, index, $event)"
              src="@/assets/gfx/drawingc.svg"
              class="direct-link"
            />
          </a>
        </div>
      </div>
      <div class="swiper-scrollbar" ref="scrollbar"></div>
    </div>
  </div>
</template>
<script>
import { Swiper, Scrollbar, Keyboard, Mousewheel } from "swiper"; // eslint-disable-line
import "swiper/css";
import "swiper/css/scrollbar";
Swiper.use([Mousewheel, Scrollbar]);
// po braku wynikow wyszukiwania nie laduja sie listingi
// cos ze swiperem - trzeba to poprawic

export default {
  name: "AsideSearch",
  props: ["results", "ready"],
  data: function () {
    return {
      active: false,
      currentItem: this.results[0],
      swiper: null,
      key: true,
      title: null,
      index: 0,
      customlink: null,
    };
  },
  mounted: function () {
    this.setUpList();
    let _this = this;
    setTimeout(() => {
      _this.swiper.update();
    }, 500);
    this.$emitter.on("showItem", (p) => {
      this.index = p[1];
      this.swiper.slideTo(p[1]);
    });
  },
  watch: {
    $route(to, from) {
      if (to.name != from.name) {
        if (this.swiper) this.swiper.destroy();
        this.setUpList();
        //let _this = this;
        setTimeout(() => {
          //_this.setUpList();
        }, 500);
      }
    },
  },
  methods: {
    toggleSidebar() {
      this.$emitter.emit("toggleSidebar", {});
      this.active = !this.active;
    },
    setUpList() {
      this.swiper = new Swiper(this.$refs.swiper, {
        modules: [Keyboard, Scrollbar],
        direction: "vertical",
        init: true,
        autoHeight: true,
        slidesPerView: "auto",
        centeredSlides: false,
        centeredSlidesBounds: true,
        initialSlide: 0,
        lazyLoading: true,
        mousewheel: true,
        freeMode: true,
        scrollbar: {
          el: this.$refs.scrollbar,
          draggable: true,
          dragSize: 100,
          hide: false,
        },
        keyboard: {
          enabled: true,
        },
      });
    },
    changeCurrentItem() {
      this.$emitter.emit("showItem", [this.currentItem, this.index]);
    },
    linkCurrentItem(r, i, e) {
      e.preventDefault();
      this.$emitter.emit("showItem", [r, i]);
      this.$emitter.on("updateLink", (x) => {
        if (x.relations[0].path && !this.customlink) {
          this.customlink = true;
          this.$router.push("/project" + x.relations[0].path);
        }
      });
    },
  },
};
</script>
<style scoped></style>
