<template>
  <div class="aside-metadata aside-single">
    <h1 v-if="project">
      <span class="uppercase" v-if="this.$i18n.locale == 'en'">{{
        metadata.etitle
      }}</span>
      <span class="uppercase" v-else>{{ metadata.title }}</span>
    </h1>
    <div class="objects-wrapper">
      <p class="uppercase">
        {{ $t("project:contains") }} {{ this.items.length }} {{ this.sheets }}.
      </p>
      <metadata-widget :metadata="metadata" />
    </div>
  </div>
</template>
<script>
import MetadataWidget from "@/components/Asides/Metadata/MetadataWidget.vue";
import { polishPlurals } from "polish-plurals";
/* eslint-disable */
import plT from "@/scripts/plT";

// zmienic
export default {
  name: "AsideContest",
  props: ["project", "metadata", "items"],
  components: {
    MetadataWidget
  },
  data: function () {
    return {
      active: false,
      sheets: null
    };
  },
  mounted() {
    this.countSheets();
    let s = plT(12);
    let _this = this;
    this.$emitter.on("localeChanged", () => {
      _this.countSheets();
    });
  },
  methods: {
    plT,
    countSheets() {
      if (this.$i18n.locale == "pl") {
        this.sheets = polishPlurals(
          this.$t("project:sheet"),
          this.$t("project:sheets"),
          this.$t("project:more-sheets"),
          this.items.length
        );
      } else {
        this.sheets = polishPlurals(
          this.$t("project:sheet"),
          this.$t("project:sheets"),
          this.$t("project:sheets"),
          this.items.length
        );
      }
    },
    toggleSidebar() {
      this.$emitter.emit("toggleSidebar", {});
      this.active = !this.active;
    }
  }
};
</script>
