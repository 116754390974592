<template>
  <div v-if="this.$i18n.locale == 'pl' && ready" class="menu-content">
    <router-link
      v-for="(m, index) in this.menu.pl"
      :key="index"
      :to="m.path"
      @click.stop="closeMenu()"
      >{{ m.label }}</router-link
    >
  </div>
  <div v-if="this.$i18n.locale == 'en' && ready" class="menu-content">
    <router-link
      v-for="(m, index) in this.menu.en"
      :key="index"
      :to="m.path"
      @click.stop="closeMenu()"
      >{{ m.label }}</router-link
    >
  </div>
  <div class="menu-content">
    <a href="https://www.instagram.com/re_creatio_ckc/" target="_blank">
      <img src="@/assets/gfx/instagram.svg" />
    </a>
  </div>
</template>
<script>
import httpClient from "@/scripts/httpClient";

export default {
  name: "DefaultMenu",
  data() {
    return {
      data: null,
      nav: null,
      menu: null,
      ready: false,
    };
  },
  created: function () {
    this.sendReq();
  },
  watch: {
    // whenever question changes, this function will run
    menu: {
      deep: true,
      handler(n) {
        if (n.pl.length + n.en.length == Number(this.nav)) {
          this.ready = true;
        }
      },
    },
  },
  methods: {
    sendReq() {
      httpClient(this.customConfig.API_PATH + "/sites").then((resp) => {
        // only one
        this.data = resp.data;
        this.setUpMenu();
      });
    },
    closeMenu() {
      this.$emitter.emit("toggleSidebar", {});
    },
    setUpMenu() {
      this.nav = 0;

      this.menu = { pl: [], en: [] };

      this.data.forEach((_site) => {
        if (_site["o:navigation"].length > 0) {
          this.nav += _site["o:navigation"].length;
          _site["o:navigation"].forEach((m, i) => {
            let _l = _site["o:slug"].split("-").pop();
            if (m.type == "url") {
              if (_l == "pl") {
                this.menu.pl.push({
                  path: m.data.url,
                  label: m.data.label,
                  order: i,
                  lang: _l,
                });
              } else {
                this.menu.en.push({
                  path: m.data.url,
                  label: m.data.label,
                  order: i,
                  lang: _l,
                });
              }
              // if _l pl push to menu.pl itp
            } else if (m.type == "page") {
              httpClient(
                this.customConfig.API_PATH + "/site_pages/" + m.data.id
              ).then((resp) => {
                if (_l == "pl") {
                  this.menu.pl.push({
                    path: "/page/" + resp.data["o:slug"],
                    label: resp.data["o:title"],
                    order: Number(100 + i),
                    lang: _l,
                  });
                } else {
                  this.menu.en.push({
                    path: "/page/" + resp.data["o:slug"],
                    label: resp.data["o:title"],
                    order: Number(100 + i),
                    lang: _l,
                  });
                }
              });
            }
            this.menu.pl.sort((a, b) => b.order - a.order);
            this.menu.en.sort((a, b) => b.order - a.order);
            //console.log(this.menu);
          });
        }
      });
    },
  },
};
</script>
