<template>
  <basic
    :title="title"
    :aside="aside"
    :main="main"
    :person="person"
    :breadcrumbs="breadcrumbs"
    v-if="ready"
  />
</template>
<script>
/* eslint-disable */
// common scripts

import httpClient from "@/scripts/httpClient";
import findTranslatedData from "@/scripts/findTranslatedData";
import iterateMetadataFields from "@/scripts/iterateMetadataFields";
import { bodyTag } from "@/scripts/bodyTag";

// base component
import Basic from "@/components/Basic.vue";

/* eslint-disable */
export default {
  name: "PersonView",
  components: {
    Basic
  },
  data: function () {
    return {
      data: null,
      person: null,
      relations: null,
      aside: "aside-person",
      main: "main-person",
      ready: null,
      title: this.customConfig.PAGE_TITLES.HOME,
      breadcrumbs: null,
      page: 1,
      count: null
    };
  },
  watch: {
    relations: {
      deep: true,
      handler(relations) {
        if (relations.length != Number(this.count)) {
          this.page++;
          this.sendReq();
        } else {
          this.setUpPerson();
          this.ready = true;
        }
      }
    }
  },
  created: function () {
    this.sendReq();
    bodyTag(this.$route.name);
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.breadcrumbs = {};
      vm.breadcrumbs.from = from;
    });
  },
  methods: {
    updateImageCaption() {
      if (typeof this.data["o:media"] !== "undefined") {
        httpClient(
          this.customConfig.API_PATH +
            "/media/" +
            this.data["o:media"][0]["o:id"]
        )
          .then((resp) => {
            this.person.image = {
              title: resp.data["o:title"],
              etitle: findTranslatedData("dcterms:title", resp.data),
              fields: iterateMetadataFields(resp.data)
            };
          })
          .catch((error) => {
            //console.log(error);
            this.$router.push({ name: "error-page", params: { error: error } });
          });
      }
    },
    sendReq() {
      this.$Progress.start();
      // basic person metadata
      if (!this.data) {
        httpClient(
          this.customConfig.API_PATH + "/items/" + this.$route.params.person_id
        )
          .then((resp) => {
            this.data = resp.data;
          })
          .catch((error) => {
            this.$router.push({ name: "error-page", params: { error: error } });
          });
      }
      // person relations
      // problem ze stronicowaniem
      httpClient(
        this.customConfig.API_PATH +
          "/items?property[0][joiner]=and&property[0][property]=5389&property[0][type]=res&property[0][text]=" +
          this.$route.params.person_id +
          "&resource_template_id[]=6&sort_by=created&sort_order=desc&page=" +
          this.page
      )
        .then((resp) => {
          this.count = Number(resp.headers["omeka-s-total-results"]);
          if (!this.relations) {
            this.relations = [];
            this.relations = this.relations.concat(resp.data);
          } else {
            this.relations = this.relations.concat(resp.data);
          }
        })
        .catch((error) => {
          this.$router.push({ name: "error-page", params: { error: error } });
        });
    },
    setUpPerson() {
      let _description = null;
      let _edescription = null;

      // deprec
      // if (typeof this.data["bio:biography"] !== "undefined") {
      //   if (this.data["bio:biography"].length > 0) {
      //     _description = this.data["bio:biography"][0]["@value"];
      //     _edescription = findTranslatedData(["bio:biography"], this.data);
      //   }
      // }

      let _metadata = {
        title: this.data["o:title"],
        etitle: findTranslatedData("foaf:name", this.data),
        fields: iterateMetadataFields(this.data),
        network: null
      };

      this.person = {
        id: this.data["o:id"],
        title: this.data["o:title"],
        etitle: findTranslatedData("foaf:name", this.data),
        description: _description,
        edescription: _edescription,
        uri: "/",
        cover: this.data["thumbnail_display_urls"].large,
        thumbnail: this.data["thumbnail_display_urls"].medium,
        network: { nodes: [], edges: [] },
        metadata: _metadata,
        image: null
      };

      this.updateImageCaption();

      // fix to show projects

      if (typeof this.data["@reverse"] !== "undefined") {
        if (typeof this.data["@reverse"]["dcterms:creator"] !== "undefined") {
          let _c = this.data["@reverse"]["dcterms:creator"];

          _c.forEach((e) => {
            let _label = e["o:title"];
            let _elabel = e["o:title"];

            httpClient(
              this.customConfig.API_PATH +
                "/items/" +
                Number(e["@id"].replace(/\D/g, ""))
            )
              .then((resp) => {
                //console.log(resp);

                let _i = resp.data;

                if (typeof _i["dcterms:alternative"] !== "undefined") {
                  if (_i["dcterms:alternative"].length > 0) {
                    _i["dcterms:alternative"].forEach((at) => {
                      if (at["@language"] === "pl") _label = at["@value"];
                      if (at["@language"] === "en") _elabel = at["@value"];
                    });
                  }
                }
              })
              .catch((error) => {
                console.log(error);
                // this.$router.push({
                //   name: "error-page",
                //   params: { error: error }
                // });
              })
              .then(() => {
                this.person.network.nodes.push({
                  id: Number(e["@id"].replace(/\D/g, "")),
                  shape: "circularImage",
                  image: null,
                  label: _label,
                  elabel: _elabel,
                  type: "project",
                  parent_to_id: null
                });
              });
          });
        }
      }

      // then remove duplicates

      this.relations.forEach((e) => {
        //console.log(e);
        // tutaj req zeby wyciagnac tlumaczenia
        if (typeof e["foaf:pastProject"] !== "undefined") {
          // default
          let _label = e["foaf:pastProject"][0]["display_title"];
          let _elabel = e["foaf:pastProject"][0]["display_title"];

          httpClient(
            this.customConfig.API_PATH +
              "/items/" +
              e["foaf:pastProject"][0]["value_resource_id"]
          )
            .then((resp) => {
              //console.log("--------------------");
              //console.log(resp);

              // use this to update images!

              let _i = resp.data;

              if (typeof _i["dcterms:alternative"] !== "undefined") {
                if (_i["dcterms:alternative"].length > 0) {
                  _i["dcterms:alternative"].forEach((at) => {
                    if (at["@language"] === "pl") _label = at["@value"];
                    if (at["@language"] === "en") _elabel = at["@value"];
                  });
                }
              }

              if (typeof _i["dcterms:isPartOf"] !== "undefined") {
                httpClient(
                  this.customConfig.API_PATH +
                    "/items/" +
                    _i["dcterms:isPartOf"][0]["value_resource_id"]
                )
                  .then((resp) => {
                    //console.log("--------------------");
                    //console.log(resp);

                    let _p = resp.data;

                    let _plabel = _p["o:title"];
                    let _pelabel = _p["o:title"];

                    if (typeof _p["dcterms:alternative"] !== "undefined") {
                      if (_p["dcterms:alternative"].length > 0) {
                        _p["dcterms:alternative"].forEach((at) => {
                          if (at["@language"] === "pl") _plabel = at["@value"];
                          if (at["@language"] === "en") _pelabel = at["@value"];
                        });
                      }
                    }

                    this.person.network.nodes.push({
                      id: _i["dcterms:isPartOf"][0]["value_resource_id"],
                      shape: "circularImage",
                      image: _p["thumbnail_display_urls"]["medium"],
                      label: _plabel,
                      elabel: _pelabel,
                      type: "project", //?
                      parent_to_id:
                        e["foaf:pastProject"][0]["value_resource_id"] // relacja do projektu
                    });

                    this.person.network.nodes.push({
                      id: e["foaf:pastProject"][0]["value_resource_id"],
                      shape: "circularImage",
                      image: e["foaf:pastProject"][0]["thumbnail_url"],
                      label: _label,
                      elabel: _elabel,
                      type: "item",
                      parent_to_id: null
                    });
                    // remove dupl
                    this.person.network.nodes =
                      this.person.network.nodes.filter(
                        (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
                      );
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
            })
            .catch((error) => {
              console.log(error);
            })
            .then(() => {
              //console.log(this.person.network.nodes);
              this.person.network.nodes.sort((a, b) => a.label.localeCompare(b.label));
            });
        }
      });

      // then remove duplicates

      this.person.network.nodes = this.person.network.nodes.filter(
        (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
      );

      this.person.network.nodes.sort((a, b) => a.label.localeCompare(b.label));

      let a = this.person.network.nodes.length;
      let i = 1;
      let project_ids = [];

      // czy to jest konieczne
      // deprec?

      this.person.network.nodes.forEach((e) => {
        httpClient(this.customConfig.API_PATH + "/items/" + e.id).then(
          (resp) => {
            // get images if null

            //console.log(resp.data);

            if (!e.image) {
              if (typeof resp.data["thumbnail_display_urls"] !== "undefined") {
                e.image = resp.data["thumbnail_display_urls"]["medium"];
              }
            }

            if (typeof resp.data["dcterms:isPartOf"] !== "undefined") {
              // one sheet - one project!
              if (
                project_ids.includes(
                  resp.data["dcterms:isPartOf"][0]["value_resource_id"]
                )
              ) {
                // find by id & push to parent_to_id
                let p = this.person.network.nodes.findIndex(
                  (x) =>
                    x.id ==
                    resp.data["dcterms:isPartOf"][0]["value_resource_id"]
                );
                // tu bład fixed!
                if (this.person.network.nodes[p].parent_to_id) {
                  this.person.network.nodes[p].parent_to_id.push(e.id);
                }
              } else {
                project_ids.push(
                  resp.data["dcterms:isPartOf"][0]["value_resource_id"]
                );
                this.person.network.nodes.push({
                  id: resp.data["dcterms:isPartOf"][0]["value_resource_id"],
                  shape: "circularImage",
                  image: resp.data["dcterms:isPartOf"][0]["thumbnail_url"],
                  label: resp.data["dcterms:isPartOf"][0]["display_title"],
                  type: "project",
                  parent_to_id: [e.id]
                });
              }
            }
            i++;
            // check the end of the request loop
            if (a === i) {
              this.person.network.nodes.unshift({
                id: this.data["o:id"],
                shape: "circularImage",
                image: this.data["thumbnail_display_urls"].medium,
                label: this.data["o:title"],
                type: "person",
                fixed: false,
                size: 50,
                parent_to_id: this.person.network.nodes
                  .filter((x) => x.type == "project")
                  .map((obj) => obj.id)
              });
              // building edges
              this.person.network.nodes.forEach((e) => {
                // get only person & projects
                if (e.parent_to_id) {
                  if (Array.isArray(e.parent_to_id)) {
                    e.parent_to_id.forEach((ee) => {
                      this.person.network.edges.push({
                        from: e.id,
                        to: ee,
                        arrows: "to",
                        label: "test2",
                        length: 200
                      });
                    });
                  } else {
                    this.person.network.edges.push({
                      from: e.id,
                      to: e.parent_to_id,
                      arrows: "to",
                      label: "test",
                      length: 200
                    });
                  }
                }
              });
              this.person.network.nodes.sort((a, b) =>
                a.label.localeCompare(b.label)
              );
              _this.ready = true;
            }
          }
        );
        // remove dupl!
        //const ids = this.person.network.nodes.map(o => o.id);
        //this.person.network.nodes.filter(({id}, index) => !ids.includes(id, index + 1));
      });

      let _this = this;
      setTimeout(function () {
        _this.$Progress.finish();
        //_this.ready = true;
        //console.log(_this.person);
      }, 100);
    }
  }
};
</script>
<style scoped></style>
