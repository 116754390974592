<template>
  <main id="main" class="page-collection" v-if="person">
    <bread-crumbs
      :title="person.title"
      :etitle="person.etitle"
      :breadcrumbs="breadcrumbs"
      :key="key"
    />
    <div class="project-viewer">
      <div class="project-image">
        <div :style="'background-image:url(' + person.cover + ');'"></div>
      </div>
      <image-caption :person="person" v-if="person.image" />
      <div class="project-slider swiper"></div>
    </div>
  </main>
</template>
<script>
import BreadCrumbs from "@/components/BreadCrumbs.vue";
import ImageCaption from "@/components/Content/ImageCaption.vue";

// eslint-disable-next-line
export default {
  name: "PersonComponent",
  props: ["person", "breadcrumbs"],
  components: {
    BreadCrumbs,
    ImageCaption,
  },
  data: function () {
    return {
      key: true,
    };
  },
  mounted: function () {},
  methods: {},
  watch: {
    $route() {
      this.key = !this.key;
    },
  },
};
</script>
