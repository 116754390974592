export default function findTranslatedData(t, i) {
  let m = null;
  if (typeof i[t] !== "undefined") {
    m = i[t].find((t) => t["@language"] == "en");
    if (m) {
      m = m["@value"];
    } else {
      m = i[t][0]["@value"];
    }
  }
  return m;
}
