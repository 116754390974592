<template>
  <div class="aside-metadata aside-person">
    <h1>
      <span class="uppercase">{{ $t("search:nothing_found") }}</span>
    </h1>
    <div class="objects-wrapper"></div>
  </div>
</template>
<script>
/* eslint-disable */

// zmienic
export default {
  name: "AsideNotFound",
  components: {},
  data: function () {
    return {
      active: false,
      key: true,
    };
  },
  mounted() {
    this.key != this.key;
    let _this = this;
  },
  methods: {
    toggleSidebar() {
      this.$emitter.emit("toggleSidebar", {});
      this.active = !this.active;
    }
  }
};
</script>
