<template>
  <footer>
    <a
      v-if="this.$i18n.locale == 'pl'"
      href="https://uw.edu.pl/"
      target="_blank"
      ><img src="@/assets/gfx/uw.svg"
    /></a>
    <a
      v-if="this.$i18n.locale == 'en'"
      href="https://uw.edu.pl/"
      target="_blank"
      ><img src="@/assets/gfx/uw_eng.svg"
    /></a>
    <a href="https://ckc.uw.edu.pl/" target="_blank"
      ><i class="icon-CKC"></i
    ></a>
  </footer>
</template>
<script>
export default {
  name: "DefaultFooter",
};
</script>
