<template>
  <div class="metadata-list" v-if="metadata">
    <div
      v-if="metadata.network && metadata.network.authors.length > 0"
      class="metadata-authors"
    >
      <span
        class="metadata-header"
        v-html="$t('metadata:authors_heading')"
        v-if="metadata.network.authors.length > 1"
      ></span>
      <span
        class="metadata-header"
        v-html="$t('metadata:author_heading')"
        v-if="metadata.network.authors.length == 1"
      ></span>
      <ul v-if="this.$i18n.locale == 'pl'">
        <li v-for="(author, index) in metadata.network.authors" :key="index">
          <router-link :to="'/person/' + author.id">
            {{ author.name }} ({{ author.role }})
          </router-link>
        </li>
      </ul>
      <ul v-if="this.$i18n.locale == 'en'">
        <li v-for="(author, index) in metadata.network.authors" :key="index">
          <router-link :to="'/person/' + author.id">
            {{ author.name }} ({{ author.enrole }})
          </router-link>
        </li>
      </ul>
    </div>
    <ul v-if="this.$i18n.locale == 'pl'" class="metadata-fields">
      <li v-for="(meta, index) in metadata.fields" :key="index">
        <div v-for="(value, index) in meta.values" :key="index">
          <div
            v-if="value['@language'] == 'pl' && meta.property_id != 5439"
            :meta-property-id="meta.property_id"
            :label="$t(plT(meta.property_id))"
            class="metadata-field"
          >
            <div class="uppercase" v-html="$t(plT(meta.property_id))"></div>
            <div v-if="meta.type == 'literal'" v-html="value['@value']"></div>
            <div v-if="meta.type == 'uri'">
              <a class="metadata-link" :href="value['@id']" target="_blank"
                >{{ value["@value"] }}
              </a>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <ul v-if="this.$i18n.locale == 'en'" class="metadata-fields">
      <li v-for="(meta, index) in metadata.fields" :key="index">
        <div v-for="(value, index) in meta.values" :key="index">
          <div
            v-if="value['@language'] == 'en' && meta.property_id != 5439"
            :meta-property-id="meta.property_id"
            :label="$t(plT(meta.property_id))"
            class="metadata-field"
          >
            <div class="uppercase" v-html="$t(plT(meta.property_id))"></div>
            <div v-if="meta.type == 'literal'" v-html="value['@value']"></div>
            <div v-if="meta.type == 'uri'">
              <a class="metadata-link" :href="value['@id']" target="_blank"
                >{{ value["@value"] }}
              </a>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <div class="sm-logos" v-if="this.$route.name !== 'person'">
      <div class="uppercase">{{ $t("social_share") }}</div>
      <a :href="'http://twitter.com/share?url=' + this.uri" target="_blank">
        <img src="@/assets/gfx/twitter.svg" />
      </a>
      <a
        :href="'https://www.facebook.com/sharer/sharer.php?u=' + this.uri"
        target="_blank"
      >
        <img src="@/assets/gfx/facebook.svg" />
      </a>
    </div>
  </div>
</template>
<script>
import plT from "@/scripts/plT";
export default {
  name: "MetadataWidget",
  props: ["metadata"],
  data: function () {
    return {
      uri: document.location.href,
    };
  },
  mounted() {
    ////console.log(this.metadata);
  },
  methods: {
    plT,
  },
};
</script>
