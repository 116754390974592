import httpClient from "@/scripts/httpClient";

export default function buildPersonSet(i, ri = null, callback) {
  // i item
  // ri - related items data
  // callback - needs callback function to process network data
  let _a = JSON.parse(JSON.stringify(i));
  let network = null;

  // get main creators
  if (typeof _a["dcterms:creator"] !== "undefined") {
    let _c = _a["dcterms:creator"];

    network = {};
    // main creators
    network.creators = [];
    // authors and roles
    network.authors = [];

    _c.forEach((_p) => {
      network.creators.push({
        id: _p["value_resource_id"],
        name: _p["display_title"],
        cover: _p["thumbnail_url"],
      });
    });
  }

  if (ri) {
    // loop over related items and get authors
    ri.forEach((_item, index) => {
      httpClient(
        window.extconfig.API_PATH +
          "/items?property[0][joiner]=and&property[0][property]=167&property[0][type]=res&property[0][text]=" +
          _item["o:id"] +
          "&resource_template_id[]=6&sort_by=created&sort_order=desc"
      )
        .then((resp) => {
          let _p = resp.data;
          _p.forEach((a) => {
            let _role = a["dbo:relatedFunctions"][0]["@value"];
            let _enrole = a["dbo:relatedFunctions"][0]["@value"];

            let _r = _role.split(";");

            if (Array.isArray(_r) && _r.length == 2) {
              _role = _r[0];
              _enrole = _r[1];
            }

            network.authors.push({
              item_id: _a["o:id"],
              id: a["bio:agent"][0]["value_resource_id"],
              name: a["bio:agent"][0]["display_title"],
              role: _role,
              enrole: _enrole,
            });
          });
          network.authors = network.authors.filter(
            (v, i, a) =>
              a.findIndex((v2) =>
                ["id", "role"].every((k) => v2[k] === v[k])
              ) === i
          );
          if (index + 1 === ri.length) {
            callback(network);
          }
        })
        .catch((error) => {
          console.log(error);
          if (index + 1 === ri.length) {
            callback(network);
          }
        })
        .finally(() => {
          // //console.log("finally");
          // if (index + 1 === ri.length) {
          //   //console.log(["koniec pętli", network]);
          //   return network;
          // }
        });
    });
  } else {
    callback(network);
  }
}
