<template>
  <header>
    <div class="hide-me"></div>
    <nav>
      <div class="desktop-nav">
        <a
          href="javascript:void(0);"
          @click="toggleSearch"
          class="toggle-menu toggle-searchbar js-toggle-search"
          ><i class="icon-search"></i
        ></a>
        <locale-changer />
        <a
          @click="toggleMenu"
          href="javascript:void(0);"
          class="toggle-menu js-toggle-menu"
          ><i class="icon-menu_repo"></i
        ></a>
      </div>
      <nav class="main-nav menu-wrap" :class="{ active: showMenu }">
        <div class="desktop-nav">
          <a class="" @click="toggleSearch" href="javascript:void(0);"
            ><i class="icon-search"></i
          ></a>
          <a class="" @click="closeAll" href="javascript:void(0);"
            ><i class="icon-close"></i
          ></a>
        </div>
        <default-menu />
      </nav>
      <div class="search-wrap menu-wrap" :class="{ active: showSearch }">
        <a @click="closeAll" href="javascript:void(0);"
          ><i class="icon-close"></i
        ></a>

        <h2>{{ $t("search:form_title") }}</h2>
        <form ref="form">
          <input
            type="text"
            name="query"
            :placeholder="$t('search:placeholder')"
            @submit="submitForm"
            v-on:keyup.stop="searchSuggest"
            v-model="query"
            autocomplete="off"
          />
          <button @click="submitForm">{{ $t("search:form_button") }}</button>
          <search-suggest />
        </form>
      </div>
    </nav>
  </header>
</template>
<script>
import LocaleChanger from "@/components/LocaleChanger.vue";
import DefaultMenu from "@/components/DefaultMenu.vue";
//import httpClient from "@/scripts/httpClient";
import SearchSuggest from "@/components/SearchSuggest.vue";

export default {
  name: "DefaultHeader",
  components: {
    LocaleChanger,
    DefaultMenu,
    SearchSuggest,
  },
  data() {
    return {
      showMenu: false,
      showSearch: false,
      query: null,
    };
  },
  mounted: function () {
    let _this = this;
    this.$emitter.on("openMenu", function () {
      _this.showMenu = !_this.showMenu;
    });
    this.$emitter.on("openSearch", function () {
      _this.showSearch = !_this.showSearch;
    });
  },
  methods: {
    searchSuggest() {
      this.$emitter.emit("suggestResults", this.query);
    },
    submitForm(e) {
      e.preventDefault();
      this.$router.push("/search/" + this.query);
    },
    toggleMenu() {
      this.showSearch = false;
      this.showMenu = !this.showMenu;
    },
    toggleSearch() {
      this.showMenu = false;
      this.showSearch = !this.showSearch;
    },
    closeAll() {
      this.showMenu = false;
      this.showSearch = false;
    },
  },
};
</script>
<style scoped></style>
