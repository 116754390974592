<template>
  <div class="aside-metadata aside-single">
    <div class="metadata-list" v-if="metadata">
      <span class="metadata-header" v-if="metadata.network.creators">
        <ul>
          <li v-for="(author, index) in metadata.network.creators" :key="index">
            <router-link :to="'/person/' + author.id">{{
              author.name
            }}</router-link>
          </li>
        </ul>
      </span>
    </div>
    <h1 v-if="metadata.title.length > 0" class="test">
      <span class="uppercase" v-if="this.$i18n.locale == 'en'">{{
        metadata.etitle
      }}</span>
      <span class="uppercase" v-else>{{ metadata.title }}</span>
    </h1>
    <h1 v-else>
      <span class="uppercase">{{ $t("metadata:no_title") }}</span>
    </h1>
    <div class="objects-wrapper">
      <metadata-widget :metadata="metadata" />
    </div>
  </div>
</template>
<script>
import MetadataWidget from "@/components/Asides/Metadata/MetadataWidget.vue";
import plT from "@/scripts/plT";

// zmienic
export default {
  name: "AsideItem",
  props: ["item", "metadata"],
  components: {
    MetadataWidget,
  },
  data: function () {
    return {
      active: false,
    };
  },
  mounted() {},
  methods: {
    plT,
    toggleSidebar() {
      this.$emitter.emit("toggleSidebar", {});
      this.active = !this.active;
    },
  },
};
</script>
