<template>
  <basic
    :title="title"
    :aside="aside"
    :main="main"
    :person="person"
    v-if="person"
    :key="key"
    :breadcrumbs="breadcrumbs"
  />
</template>
<script>
/* eslint-disable */
// common scripts
// potrzebny key na zmiane adresu
import httpClient from "@/scripts/httpClient";
import findTranslatedData from "@/scripts/findTranslatedData";
import { bodyTag } from "@/scripts/bodyTag";

// base component
import Basic from "@/components/Basic.vue";

/* eslint-disable */
export default {
  name: "PageView",
  components: {
    Basic
  },
  data: function () {
    return {
      data: null,
      person: null,
      relations: null,
      aside: "aside-page",
      main: "main-person",
      cover: null,
      description: null,
      title: this.customConfig.PAGE_TITLES.HOME,
      key: true,
      breadcrumbs: {}
    };
  },
  created: function () {
    this.sendReq();
    bodyTag(this.$route.name);
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.breadcrumbs = {};
      vm.breadcrumbs.from = from;
    });
  },
  mounted: function () {
    if (this.data) this.checkPageLanguage(this.data);
  },
  watch: {
    $route(to, from) {
      if (to.name == "page") {
        this.sendReq();
        this.key = !this.key;
      }
      //this.sendReq();
    }
  },
  methods: {
    updateImageCaption() {
      //??????????????
      //console.log(this.data["o:media"]);
      if (typeof this.data["o:media"] !== "undefined") {
        httpClient(
          this.customConfig.API_PATH +
            "/media/" +
            this.data["o:media"][0]["o:id"]
        )
          .then((resp) => {
            this.person.image = {
              title: resp.data["o:title"],
              etitle: findTranslatedData("dcterms:title", resp.data),
              fields: iterateMetadataFields(resp.data)
            };
          })
          .catch((error) => {
            this.$router.push({ name: "error-page", params: { error: error } });
          });
      }
    },
    checkPageLanguage(p) {
      //console.log("checkPageLanguage");
      //console.log(p);
      if (typeof p[0]["o:site"] !== "undefined") {
        let _s = p[0]["o:site"]["o:id"];
        //console.log("_S: " + _s);
        //https://gist.github.com/lennontu/a9969084a81385e0958bc622748320fc
        if (_s == 2) {
          //console.log("wybor 1");
          this.$emitter.emit("changeLocaleForPage", "en");
          //this.lang = "en";
          this.$i18n.locale = "pl";
        } else {
          //console.log("wybor 2");
          this.$emitter.emit("changeLocaleForPage", "pl");
          this.$i18n.locale = "en";
        }
        //console.log(_s);
      }
    },
    sendReq() {
      this.$Progress.start();
      // basic person metadata
      httpClient(
        this.customConfig.API_PATH +
          "/site_pages?slug=" +
          this.$route.params.page_slug
      )
        .then((resp) => {
          this.data = resp.data;

          // set a proper system language
          this.checkPageLanguage(this.data);

          let _blocks = this.data[0]["o:block"];

          if (_blocks.length > 0) {
            _blocks.forEach((b) => {
              if (b["o:layout"] == "asset") {
                this.cover = {};
                this.cover.id = b["o:data"][0].id;

                httpClient(
                  this.customConfig.API_PATH + "/assets?id=" + this.cover.id
                )
                  .then((resp) => {
                    this.cover.src = resp.data[0]["o:asset_url"];
                    this.setUpPerson();
                  })
                  .catch((error) => {
                    this.$router.push({
                      name: "error-page",
                      params: { error: error }
                    });
                  });
              } else if (b["o:layout"] == "html") {
                this.description = b["o:data"]["html"];
              }
            });
          }

          //this.setUpSlides();
        })
        .catch((e) => {
          this.$router.push({ name: "error-page", params: { error: e } });
        });
      // person relations
    },
    setUpPerson() {
      this.person = {
        id: this.data[0]["o:id"],
        title: this.data[0]["o:title"],
        etitle: this.data[0]["o:title"],
        description: this.description,
        edescription: this.description,
        uri: "/",
        cover: this.cover.src,
        image: null
      };

      let _this = this;

      setTimeout(function () {
        _this.$Progress.finish();
      }, 100);
    }
  }
};
</script>
<style scoped></style>
