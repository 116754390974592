<template>
  <basic
    :items="items"
    :title="title"
    :aside="aside"
    :main="main"
    v-if="items"
  />
</template>
<script>
/* eslint-disable */
// common scripts

import httpClient from "@/scripts/httpClient";
import findTranslatedData from "@/scripts/findTranslatedData";
import { bodyTag } from "@/scripts/bodyTag";

// base component
import Basic from "@/components/Basic.vue";

/* eslint-disable */
export default {
  name: "HomeView",
  components: {
    Basic
  },
  data: function () {
    return {
      data: [],
      count: null,
      items: null,
      page: 1,
      aside: "aside-slider",
      main: "main-slider",
      title: this.customConfig.PAGE_TITLES.HOME
    };
  },
  created: function () {
    this.sendReq();
    bodyTag(this.$route.name);
  },
  watch: {
    data: {
      deep: true,
      handler(data) {
        if(data.length != this.count) {
          this.page++;
          this.sendReq();
        } else {
          this.setUpSlides();
        }
      },
    }
  },
  methods: {
    sendReq() {
      this.$Progress.start();
      httpClient(
        this.customConfig.API_PATH +
          "/items?resource_template_id=7&sort_order=desc&page=" + this.page 
      )
        .then((resp) => {
          this.count = Number(resp.headers["omeka-s-total-results"]);
          this.data = this.data.concat(resp.data);
        })
        .catch((error) => {
          this.$router.push({ name: "error-page", params: { error: error } });
        });
    },
    setUpSlides() {
      // pls use http component
      this.items = [];

      // need range generator
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/from#sequence_generator_range

      var range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));

      try {
      this.data.forEach((e) => {
        
        // validate years
        let _date = e["dcterms:temporal"][0]["@value"].toString().replace(/\[/g, '').replace(/\]/g, '').split('-');
        let _range, _date2, _etitle, _title;
        if (_date.length == 2) {
           _range = range(Number(_date[0]),Number(_date[1]),1).join();
          _date2 = Number(_date[1]);
          _date = Number(_date[0]);
        } else {
          _date = Number(e["dcterms:temporal"][0]["@value"].replace(/\D/g, ''));
          _range = _date;
          _date2 = _date;
        }

        _title = e["o:title"];
        _etitle = findTranslatedData("dcterms:title", e);

        if (typeof e["dcterms:alternative"] !== "undefined") {
          if (e["dcterms:alternative"].length > 0) {
             e["dcterms:alternative"].forEach((at) => {
              if (at["@language"] === "pl") _title = at["@value"];
              if (at["@language"] === "en") _etitle = at["@value"];
             }); 
          }
        } 

        this.items.push({
          id: e["o:id"],
          title: _title,
          etitle: _etitle,
          uri: "/",
          cover: e["thumbnail_display_urls"].large,
          date: _date,
          date2: _date2,
          range: _range,
        });

        // filtrowanie
        this.items.sort((a, b) => parseFloat(a.date) - parseFloat(b.date));

      });

      let _this = this;

      setTimeout(function () {
        _this.$Progress.finish();
        ////console.log(_this.items);
      }, 100);

      } catch (error) {
        this.$router.push({ name: "error-page", params: { error: error } });
      }
    }
  }
};
</script>
<style scoped></style>
