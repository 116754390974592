<template>
  <main id="main" class="page-collection">
    <bread-crumbs
      :title="title"
      :etitle="etitle"
      :breadcrumbs="breadcrumbs"
      :key="key"
    />
    <div class="project-viewer">
      <div class="openseadragon-loading" v-if="loading">
        <img v-if="null" src="@/assets/gfx/loading.svg" />
      </div>
      <div class="project-image" id="openseadragon" :key="key" ref="osd">
        <item-download :item="item" :osd="osd" v-if="item && modal" />
        <div class="openseadragon-loading-animation" v-if="loading">
          <span />
        </div>
      </div>
      <div class="project-slider swiper"></div>
      <item-navigation :context="item.context" />
    </div>
  </main>
</template>
<script>
import BreadCrumbs from "@/components/BreadCrumbs.vue";
import ItemNavigation from "@/components/ItemNavigation.vue";
import OpenSeadragon from "openseadragon";
import ItemDownload from "@/components/ItemDownload.vue";
export default {
  name: "ItemComponent",
  props: ["item", "breadcrumbs"],
  data: function () {
    return {
      image: null,
      title: null,
      osd: null,
      loading: true,
      key: Math.random(),
      modal: false,
      button: false,
    };
  },
  components: {
    BreadCrumbs,
    ItemNavigation,
    ItemDownload,
  },
  mounted: function () {
    this.setUpImage();
    //this.setUpSlider();
    this.$emitter.on("closeModal", () => {
      this.modal = !this.modal;
    });
  },
  methods: {
    setUpImage() {
      ////console.log(this.item);
      this.title = this.item.title;
      this.etitle = this.item.etitle;
      this.setUpPlayer();
    },
    setUpPlayer() {
      // let ts =
      //   "https://recreatio.uw.edu.pl/arch/omeka/files/tile/302ad14ef22fc054d145909873d031e863c15983_zdata/ImageProperties.xml";
      //console.log(this.item);
      this.osd = OpenSeadragon({
        id: "openseadragon",
        //prefixUrl: "/openseadragon/images/ https://arch.ckc.uw.edu.pl/omeka/iiif/ ?",
        //tileSources: ["https://arch.ckc.uw.edu.pl/omeka/iiif/123/"],
        //: JSON.stringify(this.manifest),
        // https://openseadragon.github.io/docs/OpenSeadragon.html#.Options
        //tileSources: this.item.iiif,
        tileSources: this.item.tiles[0],
        preserveViewport: true,
        visibilityRatio: 1,
        minZoomLevel: 1,
        defaultZoomLevel: 0,
        sequenceMode: true,
        showReferenceStrip: true,
        preload: true,
        crossOriginPolicy: "Anonymous",
      });

      this.osd.addHandler("open", () => {
        let _this = this;

        if (!this.button) {
          this.button = true;
          let downloadButton = new OpenSeadragon.Button({
            tooltip: this.$t("item:download"),
            srcRest: "/images/download_rest.png",
            srcGroup: "/images/download_grouphover.png",
            srcHover: "/images/download_hover.png",
            srcDown: "/images/download_pressed.png",
            onClick: function () {
              _this.modal = !_this.modal;
              ////console.log(_this.item);
            },
          });

          this.osd.addControl(downloadButton.element, {
            anchor: OpenSeadragon.ControlAnchor.TOP_LEFT,
          });
        }
      });

      //let _this = this;
      this.$emitter.on("arrowsReady", () => {
        //_this.loading = false;
      });

      this.osd.addHandler("tile-loaded", (e) => {
        this.loading = false;
        this.$Progress.start();
        //console.log("jobs: " + e.eventSource.imageLoader.jobsInProgress);
        // e.eventSource.imageLoader.jobsInProgress == 0
        if (e.eventSource.imageLoader.jobsInProgress == 0) {
          let _this = this;
          this.$Progress.finish();
          setTimeout(function () {
            _this.loading = false;
          }, 1000);
        }
      });
    },
  },
};
</script>
