<template>
  <main id="main" class="page-collection" v-if="currentItem" :key="key">
    <bread-crumbs :title="title" :etitle="etitle" :breadcrumbs="breadcrumbs" />
    <div class="project-viewer">
      <div class="project-image">
        <router-link :to="currentItem.uri" v-if="currentItem.type_id != 6">
          <div
            :style="'background-image:url(' + currentItem.cover + ');'"
          ></div>
        </router-link>
        <router-link :to="'/project' + currentItem.relations[0].path" v-else>
          <div
            :style="'background-image:url(' + currentItem.cover + ');'"
          ></div>
        </router-link>
      </div>
      <div
        class="project-meta project-meta-navigation"
        v-if="this.$i18n.locale == 'en'"
      >
        <div class="item-arrows">
          <a href="#" @click.prevent="prev()" v-if="this.navigation.prev"
            ><img src="@/assets/gfx/arrow.svg"
          /></a>
        </div>
        <div class="project-meta-content">
          <span v-if="currentItem.type_id != 6">
            <router-link :to="currentItem.uri">
              {{ etitle }}
            </router-link>
          </span>
          <span v-else>
            <router-link :to="'/person/' + currentItem.relations[0].agent_id">
              {{ currentItem.relations[0].agent_title }}
            </router-link>
            &nbsp;|&nbsp;
            <!-- <router-link :to="currentItem.relations[0].path"> -->
            <router-link
              :to="'/project' + currentItem.relations[0].path"
              v-if="currentItem.relations[0].path"
            >
              {{ currentItem.relations[0].item_title }}
            </router-link>
          </span>
        </div>
        <div class="item-arrows">
          <a href="#" @click.prevent="next()" v-if="this.navigation.next"
            ><img src="@/assets/gfx/arrow.svg"
          /></a>
        </div>
      </div>
      <div
        class="project-meta project-meta-navigation"
        v-if="this.$i18n.locale == 'pl'"
      >
        <div class="item-arrows">
          <a href="#" @click.prevent="prev()" v-if="this.navigation.prev"
            ><img src="@/assets/gfx/arrow.svg"
          /></a>
        </div>
        <div class="project-meta-content">
          <span v-if="currentItem.type_id != 6">
            <router-link :to="currentItem.uri">
              {{ title }}
            </router-link>
          </span>
          <span v-else>
            <router-link :to="'/person/' + currentItem.relations[0].agent_id">
              {{ currentItem.relations[0].agent_title }}
            </router-link>
            &nbsp;|&nbsp;
            <!-- <router-link :to="currentItem.relations[0].path"> -->
            <router-link
              :to="'/project' + currentItem.relations[0].path"
              v-if="currentItem.relations[0].path"
            >
              {{ currentItem.relations[0].item_title }}
            </router-link>
          </span>
        </div>
        <div class="item-arrows">
          <a href="#" @click.prevent="next()" v-if="this.navigation.next"
            ><img src="@/assets/gfx/arrow.svg"
          /></a>
        </div>
      </div>
      <div class="project-slider swiper"></div>
    </div>
  </main>
</template>
<script>
// aktualizacja tytułu
// nie znaleziono vs loading
// tutuły w aside
import BreadCrumbs from "@/components/BreadCrumbs.vue";
export default {
  name: "SearchComponent",
  props: ["results", "ready", "breadcrumbs"],
  data: function () {
    return {
      currentItem: null,
      title: null,
      etitle: null,
      key: true,
      navigation: {
        prev: null,
        next: null,
        index: 0,
      },
    };
  },
  components: {
    BreadCrumbs,
  },
  watch: {
    $route(to, from) {
      this.key = !this.key;
      if (to.name != from.name) {
        //this.key = !this.key;
        //this.currentItem = this.results[0];
        if (typeof this.results[0].cover !== "undefined" && !this.currentItem) {
          //this.currentItem = this.results[0];
        }
        //this.swiper = null;
        //this.setUpList();
      }
    },
  },
  // watch: {
  //   results: {
  //     deep: true,
  //     handler(results) {
  //       // tutaj błąð do pilnej poprawy!
  //       if (typeof results[0].cover !== "undefined" && !this.currentItem)
  //         this.currentItem = results[0];
  //     },
  //   },
  // },
  mounted: function () {
    this.setUpImage();
    this.setUpNav();
    if (this.results.length > 0) {
      this.currentItem = this.results[0];
      this.title = this.results[0].title;
      this.etitle = this.results[0].etitle;
    }
  },
  methods: {
    next() {
      this.$emitter.emit("showItem", [
        this.results[this.navigation.index + 1],
        this.navigation.index + 1,
      ]);
    },
    prev() {
      this.$emitter.emit("showItem", [
        this.results[this.navigation.index - 1],
        this.navigation.index - 1,
      ]);
    },
    setUpNav() {
      if (this.results.length > 1) {
        this.navigation.prev = false;
        this.navigation.next = true;
      }
    },
    setUpImage() {
      let _this = this;
      this.$emitter.on("showItem", (p) => {
        _this.key = !_this.key;
        _this.currentItem = p[0];
        _this.title = p[0].title;
        _this.etitle = p[0].etitle;
        _this.navigation.index = p[1];
        // //console.log([p[1] + 1, _this.results.length]);
        // hide next?
        if (_this.navigation.index + 1 == _this.results.length) {
          _this.navigation.next = false;
        } else {
          _this.navigation.next = true;
        }
        // hide start?
        if (_this.navigation.index == 0) {
          _this.navigation.prev = false;
        } else {
          _this.navigation.prev = true;
        }
      });
    },
  },
};
</script>
