<template>
  <div
    class="project-meta project-meta-inline"
    v-if="this.$i18n.locale == 'pl'"
  >
    <ul>
      <li v-for="(meta, index) in this.person.image.fields" :key="index">
        <div v-for="(value, index) in meta.values" :key="index">
          <div
            v-if="value['@language'] == 'pl'"
            :meta-property-id="meta.property_id"
            :label="$t(plT(meta.property_id))"
            class="metadata-field"
          >
            <div
              class="uppercase"
              v-html="$t(plT(meta.property_id))"
              v-if="null"
            ></div>
            <div v-if="meta.type == 'literal'" v-html="value['@value']"></div>
            <div v-if="meta.type == 'uri'">
              <a class="metadata-link" :href="value['@id']" target="_blank">{{
                value["@value"]
              }}</a>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
  <div
    class="project-meta project-meta-inline"
    v-if="this.$i18n.locale == 'en'"
  >
    <ul>
      <li v-for="(meta, index) in this.person.image.fields" :key="index">
        <div v-for="(value, index) in meta.values" :key="index">
          <div
            v-if="value['@language'] == 'en'"
            :meta-property-id="meta.property_id"
            :label="$t(plT(meta.property_id))"
            class="metadata-field"
          >
            <div
              class="uppercase"
              v-html="$t(plT(meta.property_id))"
              v-if="null"
            ></div>
            <div v-if="meta.type == 'literal'" v-html="value['@value']"></div>
            <div v-if="meta.type == 'uri'">
              <a class="metadata-link" :href="value['@id']" target="_blank">{{
                value["@value"]
              }}</a>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import plT from "@/scripts/plT";
export default {
  name: "ImageCaption",
  props: ["person"],
  data() {
    return {
      image: null,
    };
  },
  mounted: function () {
    //console.log(this.person.image);
    this.setUpCaption();
  },
  methods: {
    plT,
    setUpCaption() {
      ////console.log("sdfsd");
      ////console.log(this.person.image);
    },
  },
};
</script>
