<template>
  <basic
    :title="title"
    :aside="aside"
    :main="main"
    :results="results"
    :key="key"
    :ready="ready"
    :breadcrumbs="breadcrumbs"
    v-if="ready && results.length > 0"
  />
  <basic
    :title="title"
    :aside="aside"
    :main="main"
    :results="results"
    :key="key"
    :ready="ready"
    :breadcrumbs="breadcrumbs"
    v-if="ready && results.length == 0"
  />
</template>
<script>
/* eslint-disable */
// common scripts
// poprawic górę!

import httpClient from "@/scripts/httpClient";
import { bodyTag } from "@/scripts/bodyTag";
import findTranslatedData from "@/scripts/findTranslatedData";

// base component
import Basic from "@/components/Basic.vue";

/* eslint-disable */
export default {
  name: "SearchView",
  components: {
    Basic
  },
  data: function () {
    return {
      key: true,
      requri: null,
      data: null,
      results: [],
      count: null,
      page: 1,
      aside: null,
      main: null,
      ready: null,
      updated: false,
      title: null,
      breadcrumbs: null
    };
  },
  created: function () {
    this.sendReq();
    bodyTag(this.$route.name);
  },
  mounted: function () {
    this.$emitter.on("showItem", (item) => {
      this.updateResult(item[0]);
    });
    this.$emitter.on("localeChanged", () => {
      this.results.sort((a, b) =>
        a.order < b.order ? -1 : Number(a.order > b.order)
      );
      if (this.$i18n.locale == "pl") {
        if (this.$route.name == "persons" || this.$route.name == "projects") {
          this.results.sort((a, b) =>
            a.title < b.title ? -1 : Number(a.title > b.title)
          );
        }
      } else {
        if (this.$route.name == "persons" || this.$route.name == "projects") {
          this.results.sort((a, b) =>
            a.etitle < b.etitle ? -1 : Number(a.etitle > b.etitle)
          );
        }
      }
    });
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.breadcrumbs = {};
      vm.breadcrumbs.from = from;
    });
  },
  watch: {
    $route(to, from) {
      if (
        to.name != from.name ||
        to.params.search_query != from.params.search_query
      ) {
        this.ready = false;
        this.page = 1;
        this.count = null;
        this.data = null;
        //this.aside = null;
        //this.main = null;
        this.results = [];
        this.key = !this.key;
        this.sendReq();
      }
    },
    results: {
      deep: true,
      handler(results) {
        if (this.count == 0) {
          let _this = this;
          setTimeout(function () {
            _this.$emitter.emit("openSearch", {});
          }, 1000);
        }
        if (results.length != Number(this.count)) {
          this.page++;
          this.sendReq();
        }
        if (results.length == Number(this.count)) {
          let _this = this;
          setTimeout(function () {
            _this.$Progress.finish();
            _this.$emitter.emit("updateSwiper", {});
            _this.ready = true;
          }, 130);
        }
      }
    }
  },
  methods: {
    sendReq() {
      this.$Progress.start();
      if (this.$route.name == "search") {
        // tutaj wywalic strony
        this.title = this.customConfig.PAGE_TITLES.SEARCH;
        this.requri =
          this.customConfig.API_PATH +
          "/items?resource_template_id[]=3&resource_template_id[]=5&resource_template_id[]=7&property[0][joiner]=and&property[0][property]=&property[0][type]=in&property[0][text]=" +
          //"/items?fulltext_search=" +
          this.$route.params.search_query +
          "&page=" +
          this.page;
      } else if (this.$route.name == "projects") {
        this.title = this.customConfig.PAGE_TITLES.PROJECTS;
        this.requri =
          this.customConfig.API_PATH +
          "/items?resource_template_id[]=7&sort_by=created&sort_order=desc&page=" +
          this.page;
      } else if (this.$route.name == "persons") {
        this.title = this.customConfig.PAGE_TITLES.PERSONS;
        this.requri =
          this.customConfig.API_PATH +
          "/items?resource_template_id[]=3&sort_by=created&sort_order=desc&page=" +
          this.page;
      } else if (this.$route.name == "contests") {
        this.title = this.customConfig.PAGE_TITLES.CONTESTS;
        this.requri =
          this.customConfig.API_PATH +
          "/items?resource_template_id[]=4&sort_by=created&sort_order=desc&page=" +
          this.page;
      }
      httpClient(this.requri)
        .then((resp) => {
          this.data = resp.data;
          this.count = Number(resp.headers["omeka-s-total-results"]);
          //console.log(this.data)

          if (this.count == 0) {
            this.aside = "aside-not-found";
            this.main = "main-not-found";
          } else {
            this.aside = "aside-search";
            this.main = "main-search";
          }

          if (resp.data.length > 0) {
            this.title = this.title + " (" + this.count + ")";
            this.setUpResults();
          } else {
            this.title = this.title + " (nic nie znaleziono)";
            this.results = [];
            this.$Progress.finish();
            this.ready = true;
          }
        })
        .catch((error) => {
          //console.log(error)
          this.$router.push({ name: "error-page", params: { error: error } });
        });
    },
    updateResult(c) {
      // c = currentItem
      if (!c.cover) {
        if (c.type_id == 6) {
          // find object in results
          let i = this.results.findIndex((x) => x.id === c.id);
          if (c.relations.length > 0 && c.relations[0].path == null) {
            this.$Progress.start();
            httpClient(
              this.customConfig.API_PATH + "/items/" + c.relations[0].agent_id
            )
              .then((resp) => {
                if (resp.data.thumbnail_display_urls.large) {
                  this.results[i].cover =
                    resp.data.thumbnail_display_urls.large;
                }
                if (c.relations[0].item_id) {
                  httpClient(
                    this.customConfig.API_PATH +
                      "/items/" +
                      c.relations[0].item_id
                  )
                    .then((resp) => {
                      // default for projects in relations
                      this.results[i].relations[0].project_id =
                        this.results[i].relations[0].item_id;
                      this.results[i].relations[0].path =
                        "/" + this.results[i].relations[0].item_id;
                      // but for plansze in relations
                      if (resp.data["dcterms:isPartOf"].length > 0) {
                        this.results[i].relations[0].project_id =
                          resp.data["dcterms:isPartOf"][0].value_resource_id;
                        this.results[i].relations[0].path =
                          "/" +
                          resp.data["dcterms:isPartOf"][0].value_resource_id +
                          "/" +
                          this.results[i].relations[0].item_id;
                      }
                      // tutaj dac tez tlumaczenie do tytulow
                      //this.updated = true;
                      //tutaj linki do search component ugotować
                      this.$Progress.finish();
                      this.$emitter.emit("updateLink", this.results[i]);
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }
              })
              .catch((error) => {
                console.log(error);
              })
              .finally(() => {
                ////console.log(this.results);
              });
          }
        }
      }
    },
    setUpResults() {
      //
      if (typeof this.results[0] !== "undefined") {
        //this.$emitter.emit("setUpItem", this.results[0]);
      }
      this.data.forEach((e) => {
        if (e["o:resource_template"]["o:id"] == 6) {
          let _relations = null;

          if (
            typeof e["bio:agent"] !== "undefined" &&
            e["bio:agent"].length == 1
          ) {
            _relations = [];
            _relations.push({
              agent_title: e["bio:agent"][0].display_title,
              agent_id: e["bio:agent"][0].value_resource_id,
              agent_cover: null,
              item_title: null,
              item_id: null,
              project_id: null,
              path: null
            });
          }
          if (
            typeof e["foaf:pastProject"] !== "undefined" &&
            _relations &&
            e["foaf:pastProject"].length == 1
          ) {
            _relations[0].item_title = e["foaf:pastProject"][0].display_title;
            _relations[0].item_id = e["foaf:pastProject"][0].value_resource_id;
          }
          this.results.push({
            id: e["o:id"],
            uri: "/",
            title: e["o:title"],
            etitle: e["o:title"],
            cover: e["thumbnail_display_urls"].large,
            type: "type:relation",
            type_id: e["o:resource_template"]["o:id"],
            relations: _relations,
            order: 9999
          });
        } else if (e["o:resource_template"]["o:id"] == 3) {
          this.results.push({
            id: e["o:id"],
            uri: "/person/" + e["o:id"],
            title: e["o:title"],
            etitle: e["o:title"],
            //etitle: findTranslatedData("foaf:name", e),
            cover: e["thumbnail_display_urls"].large,
            type: "type:person",
            type_id: e["o:resource_template"]["o:id"],
            relations: null,
            order: 1
          });
        } else if (e["o:resource_template"]["o:id"] == 4) {
          this.results.push({
            id: e["o:id"],
            uri: "/contest/" + e["o:id"],
            title: e["o:title"],
            cover: e["thumbnail_display_urls"].large,
            type: "type:contest",
            type_id: e["o:resource_template"]["o:id"],
            relations: null,
            order: 999
          });
        } else if (e["o:resource_template"]["o:id"] == 5) {
          let _uri;

          if (e["dcterms:isPartOf"]) {
            _uri =
              "/project/" +
              e["dcterms:isPartOf"][0].value_resource_id +
              "/" +
              e["o:id"];
          }

          // check for translations
          // default
          let _title = e["o:title"];
          let _etitle = e["o:title"];

          // try

          if (typeof e["dcterms:alternative"] !== "undefined") {
            if (e["dcterms:alternative"].length > 0) {
              e["dcterms:alternative"].forEach((at) => {
                if (at["@language"] === "pl") _title = at["@value"];
                if (at["@language"] === "en") _etitle = at["@value"];
              });
            }
          }

          this.results.push({
            id: e["o:id"],
            uri: _uri,
            title: _title,
            etitle: _etitle,
            cover: e["thumbnail_display_urls"].large,
            type: "type:item",
            type_id: e["o:resource_template"]["o:id"],
            relations: null,
            order: 3
          });
        } else {
          // check for translations
          // default
          let _title = e["o:title"];
          let _etitle = e["o:title"];

          // try

          if (typeof e["dcterms:alternative"] !== "undefined") {
            if (e["dcterms:alternative"].length > 0) {
              e["dcterms:alternative"].forEach((at) => {
                if (at["@language"] === "pl") _title = at["@value"];
                if (at["@language"] === "en") _etitle = at["@value"];
              });
            }
          }

          this.results.push({
            id: e["o:id"],
            uri: "/project/" + e["o:id"],
            title: _title,
            etitle: _etitle,
            cover: e["thumbnail_display_urls"].large,
            type: "type:project",
            type_id: e["o:resource_template"]["o:id"],
            relations: null,
            order: 2
          });
        }
      });
      // build data for first relation item
      if (this.results[0].type_id == 6) this.updateResult(this.results[0]);
      //this.updateResults();
      this.results.sort((a, b) =>
        a.order < b.order ? -1 : Number(a.order > b.order)
      );
      if (this.$route.name == "persons" || this.$route.name == "projects") {
        this.results.sort((a, b) =>
          a.title < b.title ? -1 : Number(a.title > b.title)
        );
      }
      ////console.log(this.results);
    }
  }
};
</script>
<style scoped></style>
