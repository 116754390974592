import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import ProjectView from "../views/ProjectView.vue";
import ItemView from "../views/ItemView.vue";
import PersonView from "../views/PersonView.vue";
import SearchView from "../views/SearchView.vue";
import PageView from "../views/PageView.vue";
import ContestView from "../views/ContestView.vue";
import ErrorView from "../views/ErrorView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/project/:project_id",
    name: "project",
    component: ProjectView,
  },
  {
    path: "/project/:project_id/:item_id",
    name: "item",
    component: ItemView,
  },
  {
    path: "/person/:person_id",
    name: "person",
    component: PersonView,
  },
  {
    path: "/search/:search_query",
    name: "search",
    component: SearchView,
  },
  {
    path: "/projects/",
    name: "projects",
    component: SearchView,
  },
  // depr?
  {
    path: "/contests/",
    name: "contests",
    component: SearchView,
  },
  {
    path: "/persons/",
    name: "persons",
    component: SearchView,
  },
  {
    path: "/page/:page_slug",
    name: "page",
    component: PageView,
  },
  {
    path: "/contest/:contest_id",
    name: "contest",
    component: ContestView,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "error-page",
    component: ErrorView,
    //props: true,
    //params: {
    //error: "404",
    //},
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
